import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useAnalytics } from '../../../analytics';
import { Paths } from '../../../constants/paths';
import { AuthorizationContext } from '../../../context/authorization-context';
import { useTenant } from '../../../context/tenant-context';
import UserPreferencesContextProvider from '../../../context/user-preferences-context';
import { breakpoints } from '../../../global-styles';
import { UserPreferences } from '../../account/user-preferences-modal';

import {
  Button,
  Li,
  OptionsGroup,
  Ul,
  Modal,
  ModalContents,
  ModalOpenButton,
  Tooltip,
} from '@controlrooms/components';
import { useClickOutside } from '@controlrooms/hooks';

interface UserSelectProps {
  userName: string;
}

const StyledUserDropdown = styled(Ul)`
  li > button {
    cursor: pointer;
  }
`;

const ResponsiveDeskIconWrapper = styled.div`
  display: none;
  ${breakpoints.desk} {
    display: block;
  }
`;

const ResponsiveMobileTextWrapper = styled.div`
  display: block;
  ${breakpoints.desk} {
    display: none;
  }
`;

export const UserSelect: React.FC<UserSelectProps> = ({ userName }) => {
  const acronym = (str: string) => str.match(/\b(\w)/g)?.join('');
  const [isUserSelectOpen, setIsUserSelectOpen] = useState(false);
  const { canUserReadAlert } = useContext(AuthorizationContext);
  const { logout } = useAuth0();

  const userUlRef = useRef(null);
  const { track } = useAnalytics();
  useClickOutside(userUlRef, () => setIsUserSelectOpen(false));
  const navigate = useNavigate();

  const { tenant } = useTenant();

  useEffect(() => {
    track('User Icon', {
      userSelectOpened: isUserSelectOpen,
    });
    // eslint-disable-next-line
  }, [isUserSelectOpen]);

  return (
    <OptionsGroup className="user-select">
      <ResponsiveDeskIconWrapper>
        <Button
          data-testid="account-settings"
          buttonSize="large"
          buttonType="icon"
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isUserSelectOpen}
          onClick={(evt) => {
            evt.stopPropagation();
            setIsUserSelectOpen(!isUserSelectOpen);
          }}
        >
          <Tooltip label="Account settings" place="bottom,left">
            <span className="user-name">{acronym(userName as string)}</span>
          </Tooltip>
        </Button>
      </ResponsiveDeskIconWrapper>
      <ResponsiveMobileTextWrapper>
        <Button
          data-testid="account-settings"
          buttonSize="large"
          buttonType="icon"
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isUserSelectOpen}
          onClick={(evt) => {
            evt.stopPropagation();
            setIsUserSelectOpen(!isUserSelectOpen);
          }}
        >
          <a>Account</a>
        </Button>
      </ResponsiveMobileTextWrapper>
      <StyledUserDropdown isOpen={isUserSelectOpen} ref={userUlRef} className="user-dropdown">
        {canUserReadAlert && (
          <Li>
            <button
              onClick={() => {
                navigate({
                  pathname: generatePath(Paths.MANAGE_LIMITS, {
                    tenantId: tenant.toString(),
                  }),
                });
              }}
            >
              Limit Manager
            </button>
          </Li>
        )}
        <Li data-testid="user-preferences" className="user-prefs-menu-item">
          <UserPreferencesContextProvider>
            <Modal>
              <ModalOpenButton>
                <button>User Preferences</button>
              </ModalOpenButton>
              <ModalContents title="User Preferences" closeButtonText="Close">
                <UserPreferences />
              </ModalContents>
            </Modal>
          </UserPreferencesContextProvider>
        </Li>
        <Li data-testid="log-out" id="logout" className="logout">
          <button
            onClick={() => {
              track('User Logout', {
                logoutUser: true,
                returnTo: window.location.origin,
              });
              sessionStorage.clear();
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}
          >
            Log Out
          </button>
        </Li>
      </StyledUserDropdown>
    </OptionsGroup>
  );
};
