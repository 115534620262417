import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { Virtuoso } from 'react-virtuoso';

import { useAnalytics } from '../../../app/analytics';
import { FolderSort, useFilteredFolders, usePlantFolders } from '../../../app/hooks/folders';
import { AnalyzeContext } from '../../context/analyze-context';
import { SearchContext } from '../../context/search-context';

import { ExpandIcon } from './styles/styles';
import {
  SubSystemContainer,
  SubSystemViewContainer,
  SystemSectionContainer,
} from './styles/system-styles';
import { SystemAndTagHeaderContent, TagComponent } from './tag-view';

import { Checkbox, Icon } from '@controlrooms/components';
import { ParentTag, SubSystem, System, Tag } from '@controlrooms/models';

interface FlattenedItem {
  type: 'root' | 'folder' | 'tag';
  item: System<SubSystem> | SubSystem | Tag;
}

// Helper function to flatten the folder structure
const flattenFolders = (folder: SubSystem): FlattenedItem[] => {
  const result: FlattenedItem[] = [];

  // If it's a folder (SubSystem with tags)
  result.push({ type: 'folder', item: folder });

  // Add all the tags under the folder
  folder.tags.forEach((tag: Tag) => {
    result.push({ type: 'tag', item: { ...tag, folder: folder.folder, folderName: folder.name } });
  });

  // Recursively process subfolders
  folder.subfolders.forEach((subfolder) => {
    result.push(...flattenFolders(subfolder));
  });

  return result;
};

const flattenSystemFolders = (system: System<SubSystem>): FlattenedItem[] => {
  const result: FlattenedItem[] = [];

  // Add the system as a root folder
  result.push({ type: 'root', item: system });

  // Process each subfolder
  system.subfolders.forEach((subfolder) => {
    result.push(...flattenFolders(subfolder)); // Subfolders contain tags and deeper subfolders
  });

  return result;
};
interface SearchViewProps {
  handleCheckboxCheck: (id: number, tag?: ParentTag) => void;
  pinnedTags?: ParentTag[];
  onNav: (folderId: number) => void;
}

export const SearchView: React.FC<SearchViewProps> = ({
  handleCheckboxCheck,
  pinnedTags,
  onNav,
}) => {
  const { searchTerm } = useContext(SearchContext);
  const { selectedFolders } = useContext(AnalyzeContext);
  const { data: plant } = usePlantFolders(FolderSort.DEFAULT);
  const handleNav = (id: number) => id && onNav(id);

  // Filter the folders using the custom hook
  const data = useFilteredFolders(plant?.subfolders || []);

  const flatList = useMemo(() => {
    if (!data.length) return [];
    return data.flatMap((folder) => flattenSystemFolders(folder));
  }, [data]);

  const { track } = useAnalytics();

  return (
    <div className="search-view">
      <SystemSectionContainer className="expanded">
        <Virtuoso
          style={{ height: 'calc(80vh - 20px)' }}
          className="search-container"
          totalCount={flatList.length}
          itemContent={(index) => {
            const { type, item } = flatList[index];

            // Render Root Folders (System<SubSystem>)
            if (type === 'root') {
              const system = item as System<SubSystem>;
              return (
                <div className="section-header" data-testid="section-header">
                  <Highlighter
                    searchWords={[searchTerm]}
                    textToHighlight={system.name}
                    autoEscape={true}
                  />
                  {system.description && system.name && <span>:&nbsp;</span>}
                  {system.description && (
                    <div className="description">
                      <Highlighter
                        searchWords={[searchTerm]}
                        textToHighlight={system.description}
                        autoEscape={true}
                      />
                    </div>
                  )}
                </div>
              );
            }

            // Render Folders (SubSystem)
            if (type === 'folder') {
              const folder = item as SubSystem;
              const id = item.folder || 0;
              const isAllChecked = Boolean(selectedFolders?.includes(item.folder || 0));
              const hasTags = folder.tags.length > 0;
              const hasSubfolders = !!folder.subfolders?.length;
              const subSysViewContainerClass = classNames({
                'search-result': searchTerm.length,
                expanded: true,
              });

              const subSysContainerClass = classNames({
                checked: isAllChecked,
                'no-tags': !hasTags && hasSubfolders,
              });
              return (
                <SubSystemViewContainer className={subSysViewContainerClass}>
                  <div className="header sub-system-tag-header">
                    <SubSystemContainer className={subSysContainerClass}>
                      <Checkbox
                        checked={isAllChecked}
                        className="sub-system-check"
                        onChange={() => {
                          track('Analyze - SubSystem Tag View', {
                            checkedSubSystem: isAllChecked,
                            checkedSubSystemId: id,
                          });
                          handleCheckboxCheck(id);
                        }}
                      />
                      <div
                        className="sub-system-label"
                        onClick={() => {
                          if (searchTerm.length) handleNav(id);
                        }}
                      >
                        <SystemAndTagHeaderContent
                          name={folder?.infra_display_name || ''}
                          description={folder?.description || ''}
                          isHeader
                        />
                      </div>
                      <div
                        onClick={() => {
                          if (searchTerm.length) handleNav(id);
                        }}
                      >
                        {hasTags && !searchTerm && <ExpandIcon />}
                        <div className="nav-icon">
                          <Icon name="chevron" width="10" height="10" />
                        </div>
                      </div>
                    </SubSystemContainer>
                  </div>
                </SubSystemViewContainer>
              );
            }

            // Render Tags
            if (type === 'tag') {
              const tag = item as Tag;
              return (
                <TagComponent
                  key={tag.name}
                  folder={tag.folder || 0}
                  handleCheckboxCheck={handleCheckboxCheck}
                  pinnedTags={pinnedTags}
                  {...tag}
                />
              );
            }

            return null;
          }}
        />
      </SystemSectionContainer>
    </div>
  );
};
