import classNames from 'classnames';
import React, { useContext, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';

import { useAnalytics } from '../../../app/analytics';
import { FOLDER } from '../../../app/constants/folders';
import { Paths } from '../../../app/constants/paths';
import { useTenant } from '../../../app/context/tenant-context';
import { useUnHideTag } from '../../../app/hooks/tags';
import { AnalyzeChartContext } from '../../context/analyze-chart-context';
import { AnalyzeContext } from '../../context/analyze-context';
import { SearchContext } from '../../context/search-context';

import { ExpandIcon, NoResults } from './styles/styles';
import {
  SubSystemContainer,
  SubSystemViewContainer,
  SystemSectionContainer,
} from './styles/system-styles';
import { TagContainer } from './styles/tag-styles';

import { Button, Checkbox, Icon, Tooltip, StyledCommonTooltip } from '@controlrooms/components';
import {
  ALERT_SEARCH_PARAM,
  ICONS,
  SUB_SYSTEMS_SEARCH_PARAM,
  TAGS_SEARCH_PARAM,
} from '@controlrooms/constants';
import { ParentTag } from '@controlrooms/models';
import {
  Checkable,
  Expandable,
  FlattenedSubSystem,
  HiddenTags,
  Navigable,
} from '@controlrooms/models';

type TagProps = ParentTag & Checkable;

export const SystemAndTagHeaderContent: React.FC<{
  name: string;
  description: string;
  uom?: string;
  isHeader?: boolean;
}> = ({ name, description, isHeader = false, uom }) => {
  const { searchTerm } = useContext(SearchContext);
  const searchTermArray = searchTerm.trim().split(' ');
  const _uom = uom ? uom : '';
  const _description = `${description || ''} ${_uom ? `(${_uom})` : ''}`;

  return (
    <>
      <StyledCommonTooltip id="tag-name-description" place="right" />
      {searchTerm.length ? (
        <div className="search-mode">
          <span
            className="name"
            style={isHeader || !(description || _uom) ? { maxWidth: 'unset' } : {}}
            data-tip={name}
            data-for="tag-name-description"
            data-testid="tag-name"
          >
            <Highlighter
              className="name-wrapper"
              searchWords={searchTermArray}
              textToHighlight={name}
              autoEscape={true}
            />
          </span>
          {(_description || _uom) && (
            <span
              className="description"
              data-tip={`${_description}`}
              data-for="tag-name-description"
              data-testid="tag-description"
              data-place="right"
            >
              <Highlighter
                className=""
                searchWords={searchTermArray}
                textToHighlight={`${description && name && isHeader ? ': ' : ''}${_description}`}
                autoEscape={true}
              />
            </span>
          )}
        </div>
      ) : (
        <div className="search-mode">
          <span
            className="name"
            style={isHeader || !(description || _uom) ? { maxWidth: 'unset' } : {}}
            data-tip={name}
            data-for="tag-name-description"
            data-testid="tag-name"
          >
            <span className="name-wrapper">{name}</span>
          </span>
          {(_description || _uom) && (
            <span
              className="description"
              data-tip={`${_description}`}
              data-for="tag-name-description"
              data-testid="tag-description"
              data-place="right"
            >
              {_description}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export const TagComponent: React.FC<TagProps> = ({ handleCheckboxCheck, pinnedTags, ...tag }) => {
  const { folder, name } = tag;
  // const { track } = useAnalytics();

  const isChecked = Boolean(
    pinnedTags?.some((pinnedTag) => pinnedTag.folder === folder && pinnedTag.name === name),
  );

  const tagContainerClass = classNames({ checked: isChecked });

  const navigate = useNavigate();
  const { tenant } = useTenant();
  const [search] = useSearchParams();
  const subSystemParams = search.get(SUB_SYSTEMS_SEARCH_PARAM);
  const tagNameParams = search.get(TAGS_SEARCH_PARAM);
  const alertView = search.get(ALERT_SEARCH_PARAM);
  const removeQueryParams = () => {
    navigate({
      pathname: generatePath(Paths.VIEWS, {
        tenantId: tenant.toString(),
      }),
      search: '',
    });
  };

  // useEffect(() => {
  //   track('Analyze - Tags', {
  //     isChecked: isChecked,
  //     checkedTagName: tag.name,
  //   });
  // }, [isChecked, tag, track]);

  return (
    <TagContainer
      data-testid={`tag-${tag.name}`}
      className={tagContainerClass}
      onClick={() => {
        if (subSystemParams || tagNameParams || alertView) {
          removeQueryParams();
        }
        handleCheckboxCheck(folder, tag);
      }}
    >
      <div onClick={(e) => e.preventDefault()} data-testid={`tag-checkbox-div-${tag.name}`}>
        <Checkbox
          dataTestId={`tag-checkbox-${tag.name}`}
          checked={isChecked}
          className="tag-check"
          onChange={() => null}
        />
      </div>
      <SystemAndTagHeaderContent
        name={tag?.tag_display_name || ''}
        description={tag?.description || ''}
        uom={tag?.uom || ''}
      />
      {/* <HeatMapIndicator /> */}
    </TagContainer>
  );
};

interface iHiddenTags extends HiddenTags {
  hiddenTagCount: number;
}

const HiddenTagComponent: React.FC<iHiddenTags> = ({ ...tag }) => {
  const { folder, tag_name, tag_display_name, hiddenTagCount } = tag;
  const { unhideTagsByName } = useContext(AnalyzeChartContext);

  const { setShowHiddenTags } = useContext(AnalyzeContext);

  const { mutateAsync: unHideTag, isLoading } = useUnHideTag();

  const tagContainerClass = classNames({ checked: true });

  return (
    <TagContainer
      data-testid={`tag-${tag_name}`}
      className={tagContainerClass}
      onClick={(e) => e.preventDefault()}
    >
      <Button
        className="un-hide-tag-btn"
        type="button"
        onClick={() => {
          unhideTagsByName(folder, tag_name);
          unHideTag({ tag: tag_name, folder });
          if (hiddenTagCount === 1) setShowHiddenTags(false);
        }}
        buttonType={'icon'}
        buttonSize={'large'}
        disabled={isLoading}
      >
        {isLoading ? '...' : <Icon name={ICONS.Eye} />}
      </Button>
      <SystemAndTagHeaderContent name={tag_display_name} description={tag_name} />
    </TagContainer>
  );
};

type SubSystemTagViewProps = FlattenedSubSystem &
  Checkable &
  Navigable &
  Expandable & { parentPath?: string };

export const SubSystemTagView: React.FC<SubSystemTagViewProps> = ({
  description,
  expanded: _expanded = false,
  folder: id,
  handleCheckboxCheck,
  infra_display_name,
  pinnedTags,
  selectedFolders,
  tags,
  parentPath,
  subfolders,
  onNav = () => undefined,
}) => {
  const hasTags = !!tags?.length;
  const hasSubfolders = !!subfolders?.length;

  // state
  const [expanded, setExpanded] = useState(_expanded);
  const { track } = useAnalytics();

  // handlers
  const handleExpand = () => setExpanded((prev) => !prev);

  const isAllChecked = Boolean(selectedFolders?.includes(id));

  const handleNav = () => id && onNav(id);
  const { searchTerm } = useContext(SearchContext);
  const navigate = useNavigate();
  const { tenant } = useTenant();
  const [search] = useSearchParams();
  const subSystemParams = search.get(SUB_SYSTEMS_SEARCH_PARAM);
  const tagNameParams = search.get(TAGS_SEARCH_PARAM);
  const alertView = search.get(ALERT_SEARCH_PARAM);

  const subSysViewContainerClass = classNames({
    'search-result': searchTerm.length,
    expanded: expanded,
  });

  const subSysContainerClass = classNames({
    checked: isAllChecked,
    'no-tags': !hasTags && hasSubfolders,
  });

  const removeQueryParams = () => {
    navigate({
      pathname: generatePath(Paths.VIEWS, {
        tenantId: tenant.toString(),
      }),
      search: '',
    });
  };

  return (
    <SubSystemViewContainer className={subSysViewContainerClass}>
      <div className="header sub-system-tag-header">
        <SubSystemContainer className={subSysContainerClass}>
          <Checkbox
            checked={isAllChecked}
            className="sub-system-check"
            onChange={() => {
              if (subSystemParams || tagNameParams || alertView) {
                removeQueryParams();
              }
              track('Analyze - SubSystem Tag View', {
                checkedSubSystem: isAllChecked,
                checkedSubSystemId: id,
              });
              handleCheckboxCheck(id);
            }}
          />
          <Tooltip label={`${parentPath || ''} / ${'description'}`}>
            <div
              className="sub-system-label"
              onClick={searchTerm.length ? handleNav : handleExpand}
            >
              <SystemAndTagHeaderContent
                name={infra_display_name}
                description={description}
                isHeader
              />
            </div>
          </Tooltip>
          <div onClick={searchTerm.length ? handleNav : handleExpand}>
            {hasTags && !searchTerm.length && <ExpandIcon />}

            <div className="nav-icon">
              <Icon name={ICONS.Chevron} width="10" height="10" />
            </div>
            {/* {hasTags && <HeatMapIndicator />} */}
          </div>
        </SubSystemContainer>
      </div>
      {hasTags && (
        <div className="content">
          {tags.map((tag) => (
            <TagComponent
              key={tag.name}
              folder={id}
              handleCheckboxCheck={handleCheckboxCheck}
              pinnedTags={pinnedTags}
              {...tag}
            />
          ))}
        </div>
      )}
      {/* <StyledCommonTooltip id="tag-name-description" /> */}
    </SubSystemViewContainer>
  );
};

export interface TagViewProps extends Checkable {
  folders?: FlattenedSubSystem[];
  hiddenTags: HiddenTags[];
  searchTerm?: string;
  handlePinAll: (isChecked: boolean) => void;
  showHiddenFlag?: boolean;
}

// TODO: make reusable header section/container that system and tag views can both use
export const TagView: React.FC<TagViewProps & Navigable> = ({
  folders,
  hiddenTags,
  handleCheckboxCheck,
  handlePinAll,
  pinnedTags,
  selectedFolders,
  showHiddenFlag,
  onNav = () => undefined,
}) => {
  // state
  // TODO: wire these up in a future story
  const [expanded] = useState(true);
  const [rootFolder, ...subfolders] = folders as FlattenedSubSystem[];
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(
    pinnedTags?.length === rootFolder.tags?.length,
  );
  const isAllChecked = Boolean(selectedFolders?.includes(rootFolder?.folder));
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const { tenant } = useTenant();
  const [search] = useSearchParams();
  const subSystemParams = search.get(SUB_SYSTEMS_SEARCH_PARAM);
  const tagNameParams = search.get(TAGS_SEARCH_PARAM);
  const alertView = search.get(ALERT_SEARCH_PARAM);

  const handlePinAllCheckbox = () => {
    const newState = !isSelectAllChecked;
    setIsSelectAllChecked(newState);
    handlePinAll(newState);
    track('Analyze - Tag View', {
      isPinAllSelected: newState,
    });
  };

  const removeQueryParams = () => {
    navigate({
      pathname: generatePath(Paths.VIEWS, {
        tenantId: tenant.toString(),
      }),
      search: '',
    });
  };

  const pinnedCount = useMemo((): number => {
    const pinCount: number = pinnedTags?.filter((tag) => {
      return tag.folder === rootFolder.folder;
    }).length as number;

    return pinCount | 0;
  }, [pinnedTags, rootFolder]);

  const isAllPinned = useMemo(() => {
    return rootFolder.tags?.every((tag) => {
      return pinnedTags?.some(
        (pinnedTag) => pinnedTag.folder === rootFolder.folder && pinnedTag.name === tag.name,
      );
    });
  }, [pinnedTags, rootFolder]);

  const headerClass = classNames({
    'section-header': true,
    checked: isSelectAllChecked,
  });

  const sysSectContainerClass = classNames({
    'tag-root': true,
    expanded: expanded,
  });

  const selectRootClass = classNames({
    'select-root': true,
    checked: isAllChecked,
  });

  return (
    <div>
      <SystemSectionContainer className={sysSectContainerClass} showHiddenTag={showHiddenFlag}>
        {rootFolder && (
          <>
            {showHiddenFlag ? <header className="section-header">Hidden Tags</header> : ''}
            {((rootFolder.infra_type_id === FOLDER.IOW_INFRA_TYPE_ID && showHiddenFlag) ||
              (rootFolder.infra_type_id !== FOLDER.IOW_INFRA_TYPE_ID && !showHiddenFlag)) && (
              <div className={headerClass}>
                <div className="section-header-label" data-testid="section-header-label">
                  {!showHiddenFlag && (
                    <div
                      className="tag-check-wrapper"
                      data-testid={`tag-check-wrapper-${rootFolder.name}`}
                    >
                      <Checkbox
                        checked={isAllPinned}
                        className="tag-check"
                        onChange={() => {
                          if (subSystemParams || tagNameParams || alertView) {
                            removeQueryParams();
                          }
                          handlePinAllCheckbox();
                        }}
                      />
                    </div>
                  )}
                  <div className={`name ${showHiddenFlag ? 'hiddenFolderName' : ''}`}>
                    {rootFolder.name}
                  </div>
                  <div className="description">
                    {rootFolder.description && rootFolder.name && <span>:&nbsp;</span>}
                    {rootFolder.description}
                  </div>
                </div>
                {pinnedCount > 0 && <div className="pinned-count">{pinnedCount}</div>}
              </div>
            )}
            <div className={selectRootClass}>
              <Checkbox checked={isAllChecked} className="tag-check" onChange={() => null} />
              <div className="sub-title">Select tags in root level</div>
            </div>
            <div className="section-content" data-testid="section-content">
              {showHiddenFlag
                ? hiddenTags?.map((tag) => (
                    <HiddenTagComponent
                      key={tag?.tag_name}
                      tag_name={tag?.tag_name}
                      tag_display_name={tag?.tag_display_name}
                      folder={tag?.folder}
                      hiddenTagCount={hiddenTags?.length}
                    />
                  ))
                : rootFolder.tags?.map((tag) => (
                    <TagComponent
                      key={tag.name}
                      folder={rootFolder.folder}
                      handleCheckboxCheck={handleCheckboxCheck}
                      pinnedTags={pinnedTags}
                      {...tag}
                    />
                  ))}
              {/* <StyledCommonTooltip id="tag-name-description" place="right" /> */}
            </div>
          </>
        )}
        {!rootFolder && (
          <>
            <NoResults className="no-results">No results...</NoResults>
          </>
        )}
      </SystemSectionContainer>
      {!showHiddenFlag &&
        subfolders.map((subfolder) => (
          <SubSystemTagView
            key={subfolder.name}
            handleCheckboxCheck={handleCheckboxCheck}
            pinnedTags={pinnedTags}
            selectedFolders={selectedFolders}
            {...subfolder}
            onNav={onNav}
          />
        ))}
    </div>
  );
};
