import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { AuthorizationContext } from '../../context/authorization-context';
import { useAlertsAPI } from '../../hooks/alerts';
import { AlertConfirmationStyle } from '../../pages/alert/styles';

import { getDirtyValues } from './alert-modal';
import AlertsPreviewModal from './alerts-preview-modal';
import { Footer } from './styles';

import { Button, Modal, ModalContents, ModalContext } from '@controlrooms/components';
import { Alert } from '@controlrooms/models';

interface AlertFooterProps {
  multipleEdit: boolean;
  alertList: Alert[];
  onSuccessCallback?: () => void;
}

export const AlertFooter = ({ onSuccessCallback, multipleEdit, alertList }: AlertFooterProps) => {
  const { setIsOpen } = useContext(ModalContext);
  const [isAlertsPreviewOpen, setIsAlertsPreviewOpen] = useState(false);
  const { canUserEditAlert } = useContext(AuthorizationContext);
  const { createAlertMutation, updateAlertMutation, patchAlertMutation, deleteAlertMutation } =
    useAlertsAPI();
  const { mutateAsync: createAlert } = createAlertMutation;
  const { mutateAsync: updateAlert } = updateAlertMutation;
  const { mutateAsync: patchAlert } = patchAlertMutation;
  const { mutateAsync: deleteAlert } = deleteAlertMutation;
  const {
    handleSubmit,
    getValues,
    formState: { dirtyFields },
  } = useFormContext();
  const { id: alertId, alert_view_summary: alertViewSummary, user_id: userId } = getValues();
  const allowEdit = canUserEditAlert(userId);

  const [confirmSaveAlert, setConfirmSaveAlert] = useState({
    open: false,
    message: '',
    title: '',
  });
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState({
    open: false,
    message: '',
    title: '',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const constructEmailDestination = (alert: any) => {
    alert.alert_destination.email_destination.emails = [
      ...(alert?.alert_destination?.email_destination?.emails || []),
      ...(alert?.emailInput
        ? alert?.emailInput?.split(',')?.map((email: string) => email?.trim()) || []
        : []),
    ];
  };

  const onSubmit = async (alert: Partial<Alert>) => {
    if (multipleEdit && !isAlertsPreviewOpen) {
      setIsAlertsPreviewOpen(true);
    } else {
      try {
        if (alert.emailInput) {
          constructEmailDestination(alert);
        }
        if (multipleEdit) {
          const rule_ids = alertList.map((alert) => alert.id) as number[];
          const alertData: Partial<Alert> = getDirtyValues(dirtyFields, alert);
          if (dirtyFields.emailInput) {
            constructEmailDestination(alertData);
          }
          await patchAlert({ rule_ids, rule_info: alertData });
          setIsAlertsPreviewOpen(false);
        } else if (alert?.id) {
          await updateAlert(alert);
        } else {
          await createAlert(alert);
        }
        onSuccessCallback && onSuccessCallback();
        setConfirmSaveAlert({
          open: true,
          title: 'Alert settings saved',
          message: `Alerts${
            multipleEdit ? '' : ` for ${alert.alert_view_summary?.target_display_name || ''}`
          } have been updated and will go into effect immediately.`,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log(error);
        setIsAlertsPreviewOpen(false);
        setConfirmSaveAlert({
          open: true,
          title: 'Error',
          message: `${
            error?.response?.data?.detail ||
            `Error occurred while ${multipleEdit || alert.id ? 'updating' : 'saving'} alert`
          }.\nPlease try again.`,
        });
      }
    }
  };

  const handleDeleteAlert = async () => {
    setConfirmDeleteAlert({
      open: true,
      title: 'Alert setting delete',
      message: `Alerts for ${
        alertViewSummary?.target_display_name || ''
      } will be deleted and will go into effect immediately.`,
    });
  };

  const onDeleteConfirmation = async () => {
    try {
      await deleteAlert(alertId);
      onSuccessCallback && onSuccessCallback();
      setIsOpen(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
      setIsOpen(false);
    }
  };

  const onCloseDeleteConfirmation = () => {
    setConfirmDeleteAlert({ open: false, message: '', title: '' });
  };

  const onCloseSaveConfirmation = () => {
    setConfirmSaveAlert({ open: false, message: '', title: '' });
  };

  const getDisabledSave = () => {
    if (multipleEdit) {
      return Object.keys(dirtyFields)?.length ? false : true;
    } else {
      return alertId && !allowEdit;
    }
  };

  return (
    <Footer>
      <Button
        data-testid="cancel-edit-alert"
        buttonSize="small"
        buttonType="secondary"
        onClick={() => {
          setIsOpen(false);
        }}
      >
        Cancel
      </Button>
      <span>
        {alertId && allowEdit && (
          <Button
            data-testid="delete-alert"
            buttonSize="small"
            buttonType="primary"
            type="submit"
            onClick={() => {
              handleDeleteAlert();
            }}
          >
            Delete Alert
          </Button>
        )}

        <Modal open={confirmDeleteAlert.open}>
          <ModalContents
            title={confirmDeleteAlert.title}
            styles={{ content: { maxWidth: '350px' } }}
            confirmButtonText="OK"
            closeButtonCallback={onCloseDeleteConfirmation}
            confirmButtonCallback={onDeleteConfirmation}
            confirmButtonClassName="okConfirmation"
            dataTestIdCancel="modal-close-icon-delete-alert"
          >
            <AlertConfirmationStyle data-testid="alert-confirmation-summary">
              {confirmDeleteAlert?.message}
            </AlertConfirmationStyle>
          </ModalContents>
        </Modal>
        <Button
          data-testid="save-alert"
          buttonSize="small"
          buttonType="primary"
          type="submit"
          style={{ marginLeft: '12px' }}
          onClick={handleSubmit(onSubmit)}
          disabled={getDisabledSave()}
        >
          Save Changes
        </Button>
        <AlertsPreviewModal
          isAlertsPreviewOpen={isAlertsPreviewOpen}
          setIsAlertsPreviewOpen={setIsAlertsPreviewOpen}
          alertList={alertList}
          confirmSaveCallback={handleSubmit(onSubmit)}
          changedValues={getDirtyValues(dirtyFields, getValues())}
        />
        <Modal open={confirmSaveAlert.open}>
          <ModalContents
            title={confirmSaveAlert.title}
            styles={{ content: { maxWidth: '350px' } }}
            confirmButtonText="OK"
            closeButtonCallback={onCloseSaveConfirmation}
            confirmButtonCallback={() => {
              if (confirmSaveAlert.title === 'Error') {
                setConfirmSaveAlert({ open: false, message: '', title: '' });
                return;
              }
              onCloseSaveConfirmation();
              setIsOpen(false);
            }}
            confirmButtonClassName="okConfirmation"
            dataTestIdCancel="modal-close-icon-save-alert"
          >
            <AlertConfirmationStyle data-testid="alert-confirmation-summary">
              {confirmSaveAlert?.message}
            </AlertConfirmationStyle>
          </ModalContents>
        </Modal>
      </span>
    </Footer>
  );
};
