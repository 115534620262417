import React from 'react';

import { AlertCard } from '../../pages/alert/styles';

import { DurationTitle, SnoozeTitle } from './alert-modal';
import { AlertHeader, AlertsPreviewWrapper, InfoCard } from './styles';

import { Button, Icon, Modal, ModalContents, Switch } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { Alert, alertConditionLabels } from '@controlrooms/models';

interface AlertsPreviewModalProps {
  isAlertsPreviewOpen: boolean;
  setIsAlertsPreviewOpen: (isAlertsPreviewOpen: boolean) => void;
  alertList: Alert[];
  confirmSaveCallback?: () => Promise<void> | void;
  confirmDeleteCallback?: () => Promise<void> | void;
  changedValues?: Partial<Alert>;
  isDeletePreview?: boolean;
  error?: string;
}

export const AlertsPreviewModal: React.FC<AlertsPreviewModalProps> = ({
  isAlertsPreviewOpen,
  setIsAlertsPreviewOpen,
  alertList,
  confirmSaveCallback,
  confirmDeleteCallback,
  changedValues,
  isDeletePreview,
  error,
}) => {
  return (
    <Modal unmountOnExit open={isAlertsPreviewOpen}>
      <ModalContents
        title="Edit Multiple Alerts"
        styles={{ content: { width: '900px' } }}
        closeButtonCallback={() => {
          setIsAlertsPreviewOpen(false);
        }}
        shouldCloseOnEsc={false}
        footerPrompt={
          <div>
            <Button
              data-testid="confirm-alert-edits"
              buttonSize="small"
              buttonType="primary"
              type="submit"
              onClick={() => {
                isDeletePreview
                  ? confirmDeleteCallback && confirmDeleteCallback()
                  : confirmSaveCallback && confirmSaveCallback();
              }}
            >
              {isDeletePreview ? 'Confirm Delete' : 'Confirm Edits'}
            </Button>
          </div>
        }
      >
        <AlertsPreviewWrapper>
          <InfoCard type="info">
            <Icon className="icon" name={ICONS.Info} />
            <span data-testid="alert-error">
              {isDeletePreview ? (
                <>
                  The following table shows all the selected rules for delete.
                  <br /> Please confirm your delete.
                </>
              ) : (
                <>
                  The following table shows all the new values the alerts will have.
                  <br /> Please confirm your edits.
                </>
              )}
            </span>
          </InfoCard>
          {error && (
            <InfoCard type="error">
              <Icon className="icon" name={ICONS.SystemDown} />
              <span data-testid="alert-error">{error}</span>
            </InfoCard>
          )}
          <AlertHeader>
            <span style={{ width: '118px', padding: '0 12px' }}>
              Alert name <Icon name={ICONS.ArrowDown} style={{ paddingLeft: '4px' }} />
            </span>
            <span style={{ width: '42px', padding: '0 12px' }}>Type</span>
            <span style={{ width: '59px', padding: '0 12px' }}>
              <DurationTitle />
            </span>
            <span style={{ width: '76px', padding: '0 12px' }}>
              <SnoozeTitle width="60px" />
            </span>
            <span style={{ width: '374px', padding: '0 12px' }}>Recipients</span>
            <span style={{ width: '31px', padding: '0 12px' }}>Active</span>
          </AlertHeader>
          {alertList.map((alert) => {
            const alertData = isDeletePreview ? alert : changedValues;
            const emailDestinations: string[] = [
              ...((alertData?.alert_destination?.email_destination?.emails as string[]) || []),
              ...(alertData?.emailInput
                ? (alertData?.emailInput
                    ?.split(',')
                    ?.map((email: string) => email.trim()) as string[]) || []
                : []),
            ];
            return (
              <AlertCard key={alert?.id} isActive={true}>
                <span className="alert-name" style={{ width: '118px' }}>
                  {alert.target_system && (
                    <span className="content">{alert.target_display_name}</span>
                  )}
                  {alert.target_type === 'tag' ? (
                    <span
                      className="target-name"
                      data-testid={`${alert?.tag_description}-edit-multiple-alerts`}
                    >
                      <span
                        className="target-id"
                        data-testid={`${alert?.target_id}-edit-multiple-alerts`}
                      >
                        {alert?.target_display_name}
                      </span>
                      {alert?.tag_description || ''}
                    </span>
                  ) : (
                    <span
                      className="target-name"
                      data-testid={`${alert.target_name}-edit-multiple-alerts`}
                    >
                      {alert?.target_display_name ? alert?.target_display_name : 'No Name'}
                    </span>
                  )}
                </span>
                <div className="conditions" style={{ width: '250px' }}>
                  {Object.entries(alertData?.alert_conditions || {}).map(
                    ([key, alertCondition]) => (
                      <React.Fragment key={key}>
                        <div key={key} style={{ display: 'flex' }}>
                          <span
                            className="content"
                            style={{ width: '42px' }}
                            data-testid={`${key}-${alert.target_name}-type`}
                          >
                            {alertConditionLabels[key] || ''}
                          </span>
                          <span
                            className="content"
                            style={{ width: '59px' }}
                            data-testid={`${key}-duration-${alert.target_name}`}
                          >
                            {alertCondition?.duration ? `${alertCondition?.duration} min` : ''}
                          </span>
                          <span
                            className="content"
                            style={{ width: '76px' }}
                            data-testid={`${key}-snooze-${alert.target_name}`}
                          >
                            {alertCondition?.snooze_duration
                              ? `${alertCondition?.snooze_duration} min`
                              : ''}
                          </span>
                        </div>
                      </React.Fragment>
                    ),
                  )}
                </div>
                <span style={{ width: '374px' }} className="destination content">
                  {alertData?.alert_destination?.teams_destination && (
                    <span className="list">
                      <Icon name={ICONS.Teams} />
                      <span
                        data-testid={`teams-channel-name-${alert.target_name}`}
                        className="content"
                        style={{ padding: '0' }}
                      >
                        {alertData.alert_destination?.teams_destination?.teams_channels.map(
                          (teams_channel, index, array) =>
                            `${teams_channel?.channel_name}${
                              index === array.length - 1 ? '' : ', '
                            }`,
                        )}
                      </span>
                    </span>
                  )}
                  {emailDestinations && (
                    <span className="list">
                      <Icon name={ICONS.Envelope} />
                      <span
                        data-testid={`email-${alert.target_name}`}
                        className="content"
                        style={{ padding: '0' }}
                      >
                        {emailDestinations.join(', ')}
                      </span>
                    </span>
                  )}
                </span>
                <span className="active-switch">
                  <Switch
                    selected={
                      typeof alertData?.disabled === 'boolean' ? !alertData?.disabled : null
                    }
                    disabled={true}
                    dataTestId={'alerts_preview'}
                  />
                </span>
              </AlertCard>
            );
          })}
        </AlertsPreviewWrapper>
      </ModalContents>
    </Modal>
  );
};

export default AlertsPreviewModal;
