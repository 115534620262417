import { useAuth0 } from '@auth0/auth0-react';
import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAnalytics } from '../../../../analytics';
import { useUpdateUserProfile } from '../../../../hooks/accounts';

import { DemographicContainer, ProfileButtonGroup } from './styles';

import { Button, Icon, TextInput, TextInputType } from '@controlrooms/components';

export const UserProfile: React.FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { mutateAsync: updateProfile } = useUpdateUserProfile();
  const [nameSubmitted, setNameSubmitted] = useState(false);
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);
  const { track } = useAnalytics();

  const updateWithTimeout = (fn: Dispatch<SetStateAction<boolean>>) => {
    fn(true);
    setTimeout(() => fn(false), 5000);
  };

  const idp = useMemo(() => user?.sub?.split('|')?.[0], [user?.sub]);
  const isExternalIdp = idp !== 'auth0';

  const {
    register: registerName,
    handleSubmit: handleNameSubmit,
    formState: { errors: nameErrors },
    reset: nameFormReset,
  } = useForm({
    defaultValues: {
      first_name: user?.given_name,
      last_name: user?.family_name,
    },
  });

  const {
    register: registerPassword,
    handleSubmit: handlePasswordsSubmit,
    formState: { errors: passwordErrors },
    reset: passwordFormReset,
    watch,
  } = useForm();

  const password = useRef({});
  password.current = watch('password', '');

  if (!user) return null;

  if (isExternalIdp) {
    return (
      <>
        <span>{`Edit your profile info and password in ${idp}`}</span>
        <DemographicContainer>
          <TextInput
            data-testid="first-name-input"
            disabled
            className="field"
            label="First name"
            placeholder="First name"
            value={user.given_name}
          />
          <TextInput
            disabled
            data-testid="last-name-input"
            className="field"
            label="Last name"
            placeholder="Last name"
            value={user.family_name}
          />
          <TextInput
            disabled
            className="field email"
            data-testid="email-input"
            label="Email address"
            placeholder="Email address"
            value={user.email}
          />
        </DemographicContainer>
      </>
    );
  }

  return (
    <>
      <span>Edit your profile info and change your password</span>
      <DemographicContainer>
        <div className="name-form">
          <TextInput
            data-testid="first-name-input"
            className="field"
            {...registerName('first_name', {
              required: 'You must specify a first name',
            })}
            label="First name"
            placeholder="First name"
            errorMessage={nameErrors?.first_name?.message ?? ''}
          />
          <TextInput
            className="field"
            data-testid="last-name-input"
            {...registerName('last_name', {
              required: 'You must specify a last name',
            })}
            label="Last name"
            placeholder="Last name"
            errorMessage={nameErrors?.last_name?.message ?? ''}
          />
        </div>
        <ProfileButtonGroup>
          <Button
            buttonSize="small"
            buttonType="secondary"
            data-testid="reset-changes-name-button"
            className="reset-changes-btn"
            onClick={() => {
              track('User Preferences', {
                cancelProfileNameChanges: 'clicked',
              });
              nameFormReset();
            }}
          >
            Cancel
          </Button>
          <Button
            buttonSize="small"
            buttonType="primary"
            data-testid="save-changes-name-button"
            className="save-changes-btn"
            onClick={handleNameSubmit(({ first_name, last_name }) => {
              updateProfile({ first_name, last_name }).then(() => {
                track('User Preferences', {
                  updateUserInfo: 'clicked',
                  newFirstName: first_name,
                  newLastName: last_name,
                });
                setTimeout(() => getAccessTokenSilently({ cacheMode: 'off' }), 500);
                updateWithTimeout(setNameSubmitted);
              });
            })}
          >
            Save Changes
          </Button>
          {nameSubmitted && <Icon name="checkmark" className="update-success" />}
        </ProfileButtonGroup>
        <TextInput
          disabled
          className="field email"
          label="Email address"
          data-testid="email-input"
          placeholder="Email address"
          value={user.email}
        />
        <div className="password-form">
          <TextInput
            data-testid="change-password"
            className="field"
            {...registerPassword('password', {
              required: 'You must specify a password',
              minLength: {
                value: 8,
                message: 'Password must have at least 8 characters',
              },
              pattern: {
                value:
                  /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
                message:
                  'Password must have atleast 1 uppercase, 1 lowercase, 1 number and a special character',
              },
            })}
            type={TextInputType.PASSWORD}
            label="Change your password"
            errorMessage={passwordErrors?.password?.message ?? ''}
          />
          <TextInput
            className="field"
            data-testid="confirm-password"
            {...registerPassword('passwordConfirm', {
              validate: (value) => value === password.current || 'The passwords do not match',
            })}
            type={TextInputType.PASSWORD}
            label="&nbsp;"
            errorMessage={passwordErrors?.passwordConfirm?.message ?? ''}
          />
        </div>
        <ProfileButtonGroup>
          <Button
            data-testid="reset-changes-password-button"
            buttonSize="small"
            buttonType="secondary"
            className="reset-changes-btn"
            onClick={() => {
              track('User Preferences', {
                cancelProfilePasswordChanges: 'clicked',
              });
              passwordFormReset();
            }}
          >
            Cancel
          </Button>
          <Button
            buttonSize="small"
            buttonType="primary"
            data-testid="save-changes-password-button"
            onClick={handlePasswordsSubmit(({ password }) => {
              updateProfile({ password }).then(() => {
                track('User Preferences', {
                  updateUserPassword: 'clicked',
                });
                updateWithTimeout(setPasswordSubmitted);
              });
            })}
          >
            Save New Password
          </Button>
          {passwordSubmitted && <Icon name="checkmark" className="update-success" />}
        </ProfileButtonGroup>
      </DemographicContainer>
    </>
  );
};
