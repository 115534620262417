import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AnalyzeContext } from '../../context/analyze-context';
import { AppContext } from '../../context/app-context';
import SavedViewContextProvider from '../../context/saved-view-context';
import { SearchContext } from '../../context/search-context';
import { useGetViews } from '../../hooks/accounts';
import { useFlatFolders, usePlantFolders, FolderSort } from '../../hooks/folders';
import { useHiddenTags } from '../../hooks/tags';
import { SidebarFooter } from '../../pages/monitor/styles';
import BrowserFilter from '../browser-filter/browser-filter';
import { CustomViewManager } from '../custom-view/custom-view';

import { Search } from './search';
import { SearchView } from './search-view';
import { BackButton, BrowserHeaderSection } from './styles/styles';
import { SystemBrowserContainer } from './styles/system-styles';
import { TagViewContainer } from './styles/tag-styles';
import { SystemView } from './system-view';
import { TagView } from './tag-view';
import { ViewHiddenTag } from './view-hidden-tag';

import { Button, Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { ParentTag } from '@controlrooms/models';
import { ViewType } from '@controlrooms/models';
import { FlattenedSubSystem, HiddenTags } from '@controlrooms/models';

// should this go somewhere more central?
const SUB_SYSTEMS_SEARCH_PARAM = 'subsystems';

export const SystemBrowser = ({ width }: { width: number }) => {
  // context
  const {
    pinnedTags,
    setPinnedTags,
    selectedFolders,
    setSelectedFolders,
    currentFolder,
    setCurrentFolder,
    showHiddenTags,
    selectedViewTitle,
    setSelectedViewTitle,
  } = useContext(AnalyzeContext);
  const {
    searchTerm,
    setSearchTerm,
    setIsSearchDebounce,
    setSearchInputValue,
    setSearchTermOnNavigate,
    searchTermOnNavigate,
  } = useContext(SearchContext);

  const { data: savedCustomViews } = useGetViews(ViewType.ANALYZE);

  const [search] = useSearchParams();
  const subSystemsParams = search.get(SUB_SYSTEMS_SEARCH_PARAM);

  const { isLoading: isFoldersLoading, data: folders } = usePlantFolders(FolderSort.DEFAULT);
  const { data: flatFolders } = useFlatFolders(FolderSort.DEFAULT, {
    folderId: currentFolder as number,
  });

  const { data: hiddenTags } = useHiddenTags();

  const iowHiddenTags = useMemo(
    () =>
      hiddenTags?.result.map((t: HiddenTags) => ({
        tag_name: t.tag_name,
        tag_display_name: t.tag_display_name,
        folder: t.folder,
      })) || [],
    [hiddenTags],
  );

  const isReport = useMemo(() => search.get('report'), [search]);

  const { setIsCustomViewFormVisible } = useContext(AppContext);

  useEffect(() => {
    if (subSystemsParams) {
      setSelectedFolders(subSystemsParams?.split(',').map(Number));
    }
  }, [setSelectedFolders, subSystemsParams]);

  // handlers
  const handleCheckboxCheck = useCallback(
    (id: number, tag?: ParentTag) => {
      if (tag) {
        setPinnedTags((prevPinned) =>
          prevPinned.some(({ folder, name }) => folder === tag.folder && name === tag.name)
            ? prevPinned.filter(({ folder, name }) => !(folder === tag.folder && name === tag.name))
            : [tag, ...prevPinned],
        );
        return;
      }

      setSelectedFolders((prevSelected) =>
        prevSelected.includes(id) ? prevSelected.filter((x) => x !== id) : [id, ...prevSelected],
      );
    },
    [setPinnedTags, setSelectedFolders],
  );

  const handlePinAll = useCallback(
    (isChecked: boolean) => {
      const allTags = flatFolders?.[0]?.tags || [];
      setPinnedTags((prevPinned) => {
        const tagsNotPinned = allTags.filter((t) => {
          return !prevPinned.some((pt) => pt.name === t.name && pt.folder === t.folder);
        });

        return isChecked
          ? [...prevPinned, ...tagsNotPinned]
          : prevPinned.filter((t) => {
              return !allTags.some((at) => at.name === t.name);
            });
      });
    },
    [flatFolders, setPinnedTags],
  );

  const handleBack = useCallback(() => {
    setCurrentFolder(null);
    setIsSearchDebounce(false);
    setSearchInputValue(searchTermOnNavigate);
    setSearchTerm(searchTermOnNavigate);
  }, [
    setCurrentFolder,
    setIsSearchDebounce,
    setSearchInputValue,
    setSearchTerm,
    searchTermOnNavigate,
  ]);

  const handleSearchNav = useCallback(
    (fid: string | number) => {
      setSearchInputValue('');
      setSearchTermOnNavigate(searchTerm);
      setCurrentFolder(fid);
    },
    [setSearchInputValue, setSearchTermOnNavigate, searchTerm, setCurrentFolder],
  );

  // Conditional rendering to avoid unnecessary loads
  if (isFoldersLoading) {
    return null;
  }

  const browserView = () => {
    if (searchTerm) {
      return (
        <div className="browser-wrapper">
          <BrowserHeaderSection>
            {savedCustomViews && (
              <SavedViewContextProvider>
                <CustomViewManager
                  views={savedCustomViews}
                  title={selectedViewTitle}
                  onTitleChange={setSelectedViewTitle}
                  viewType={ViewType.ANALYZE}
                />
                <BrowserFilter />
              </SavedViewContextProvider>
            )}
            <Search />
            <div className="sub-title">
              <Icon name={ICONS.SelectTrend} width="9" height="9" className="dynamic-icon" /> Select
              trends to keep in the view.
            </div>
          </BrowserHeaderSection>
          <TagViewContainer>
            <SearchView
              handleCheckboxCheck={handleCheckboxCheck}
              pinnedTags={pinnedTags}
              onNav={handleSearchNav}
            />
          </TagViewContainer>
        </div>
      );
    }

    if (currentFolder) {
      return (
        <div className="browser-wrapper">
          <BrowserHeaderSection>
            <h3 data-testid="tag" className="system-view-title">
              Tag View
            </h3>
            <Search />
            <div className="sub-title">
              <div>
                <Icon name={ICONS.SelectTrend} width="9" height="9" className="dynamic-icon" />{' '}
                Select trends to keep in the view.
              </div>
              <div className="lower">
                <ViewHiddenTag hiddenTags={iowHiddenTags} />
              </div>
            </div>
            <BackButton
              data-testid="systems-back-button"
              className="back-button"
              onClick={handleBack}
            >
              <Icon name={ICONS.Back} width="11" height="11" className="browser-header-back" />{' '}
              Systems
            </BackButton>
          </BrowserHeaderSection>
          <TagViewContainer>
            <TagView
              folders={flatFolders as FlattenedSubSystem[]}
              hiddenTags={iowHiddenTags as HiddenTags[]}
              handleCheckboxCheck={handleCheckboxCheck}
              handlePinAll={handlePinAll}
              pinnedTags={pinnedTags}
              selectedFolders={selectedFolders}
              showHiddenFlag={showHiddenTags}
            />
          </TagViewContainer>
        </div>
      );
    }

    return (
      <div className="browser-wrapper">
        <BrowserHeaderSection>
          {savedCustomViews && (
            <SavedViewContextProvider>
              <CustomViewManager
                views={savedCustomViews}
                title={selectedViewTitle}
                viewType={ViewType.ANALYZE}
                onTitleChange={setSelectedViewTitle}
              />
              <BrowserFilter />
            </SavedViewContextProvider>
          )}
          <Search />
          <div className="sub-title">
            <div>
              <Icon name={ICONS.Dynamic} width="9" height="9" className="dynamic-icon" /> Select
              systems for dynamic view.
            </div>
            <div className="lower">
              <ViewHiddenTag hiddenTags={iowHiddenTags} />
            </div>
          </div>
        </BrowserHeaderSection>
        {showHiddenTags ? (
          <TagViewContainer>
            <TagView
              folders={flatFolders as FlattenedSubSystem[]}
              hiddenTags={iowHiddenTags as HiddenTags[]}
              handleCheckboxCheck={handleCheckboxCheck}
              handlePinAll={handlePinAll}
              pinnedTags={pinnedTags}
              selectedFolders={selectedFolders}
              showHiddenFlag={showHiddenTags}
            />
          </TagViewContainer>
        ) : (
          <SystemView
            handleCheckboxCheck={handleCheckboxCheck}
            onNav={setCurrentFolder}
            plant={folders}
            selectedFolders={selectedFolders}
          />
        )}
      </div>
    );
  };

  if (isReport) return <></>;

  return (
    <SystemBrowserContainer>
      {browserView()}
      {(pinnedTags?.length > 0 || selectedFolders?.length > 0) && (
        <SidebarFooter style={{ width: `${width}px` }}>
          <div className="gta-wrapper">
            <Tooltip
              offset="{'left': 30}"
              label={
                <>
                  Create a Custom View with <br />
                  selected systems and tags
                </>
              }
            >
              <Button
                iconName="plus"
                buttonType="text"
                buttonSize="small"
                className="add-custom-view"
                onClick={() => setIsCustomViewFormVisible(true)}
              >
                Custom View
              </Button>
            </Tooltip>
          </div>
        </SidebarFooter>
      )}
    </SystemBrowserContainer>
  );
};
