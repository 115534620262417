import dayjs from 'dayjs';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { useFolderTagsByLimit } from '../hooks/folders';

import {
  LimitConfig,
  LimitMatchType,
  MatchType,
  SubSystem,
  SystemsWithStates,
  Tag,
} from '@controlrooms/models';

interface ContextProps {
  folder: SubSystem | undefined;
  tag: Tag | undefined;
  filterString: string;
  limitMatchType: LimitMatchType;
  stateTags: Tag[];
  defaultLimit: LimitConfig;
  updateTagSelection: (
    folder: SubSystem,
    tag: Tag,
    systemsWithStates?: SystemsWithStates[],
  ) => void;
  setEditableLimit: Dispatch<SetStateAction<LimitConfig | undefined>>;
  setFilterString: Dispatch<SetStateAction<string>>;
  setLimitMatchType: Dispatch<SetStateAction<LimitMatchType>>;
  editableLimit: LimitConfig | undefined;
  matchType: MatchType;
  setMatchType: Dispatch<SetStateAction<MatchType>>;
}

const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
const defaultStartDate = dayjs().format(dateFormat);
const defaultEndDate = '2050-12-30T23:59:59';

const defaultLimit: LimitConfig = {
  limit_id: 0,
  tag_name: '',
  tag_display_name: '',
  start_time: defaultStartDate,
  end_time: defaultEndDate,
  low_low_limit: null,
  low_limit: null,
  high_limit: null,
  high_high_limit: null,
  criticality: 'Low',
  center_type: 'Numeric',
  center_value: 0,
  limit_type: 'Numeric',
  is_active: true,
  states: [],
};

const defaultState = {
  setTag: () => null,
  tag: undefined,
  defaultLimit,
  stateTags: [],
  setStateTag: () => null,
  limitMatchType: LimitMatchType.WITH_LIMITS,
  setLimitMatchType: () => null,
  matchType: MatchType.ANY,
  setMatchType: () => null,
  filterString: '',
  setFilterString: () => null,
  setFolder: () => null,
  folder: undefined,
  updateTagSelection: () => null,
  setEditableLimit: () => null,
  editableLimit: undefined,
};

export const LimitContext = createContext<ContextProps>(defaultState);

const LimitContextProvider: React.FC = ({ children }) => {
  const [tag, setTag] = useState<Tag | undefined>(); // Sets the selected tag to be displayed in the limit configuration page
  const [stateTags, setStateTag] = useState<Tag[]>([]);
  const [filterString, setFilterString] = useState('');
  const [folder, setFolder] = useState<SubSystem>();
  const [matchType, setMatchType] = useState<MatchType>(MatchType.ANY);
  const [limitMatchType, setLimitMatchType] = useState<LimitMatchType>(LimitMatchType.WITH_LIMITS);
  const [editableLimit, setEditableLimit] = useState<LimitConfig | undefined>();
  const { data: folderTags } = useFolderTagsByLimit();

  const updateTagSelection = useCallback(
    (selectedFolder, selectedTag, systemsWithStates) => {
      setFolder(selectedFolder);
      setEditableLimit(undefined);
      selectedFolder.tags.forEach((tag: Tag) => {
        if (tag.name === selectedTag.name) {
          setTag(selectedTag);
        }
      });
      const SystemStateTags =
        systemsWithStates?.find(
          (sys: SystemsWithStates) => sys.infra_node_id === selectedFolder.folder,
        ).state_tag_names || [];
      const stateTags = folderTags[selectedFolder.folder].filter((tag: Tag) =>
        SystemStateTags.includes(tag.name),
      );
      setStateTag(stateTags);
    },
    [folderTags],
  );

  const appState = useMemo(
    () => ({
      folder,
      tag,
      defaultLimit,
      stateTags,
      filterString,
      setFilterString,
      updateTagSelection,
      editableLimit,
      setEditableLimit,
      limitMatchType,
      setLimitMatchType,
      matchType,
      setMatchType,
    }),
    [
      editableLimit,
      filterString,
      folder,
      limitMatchType,
      matchType,
      stateTags,
      tag,
      updateTagSelection,
    ],
  );

  return <LimitContext.Provider value={appState}>{children}</LimitContext.Provider>;
};

export default LimitContextProvider;
