import memoizerific from 'memoizerific';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';

import { useVersion } from '../../app-v2/components/version';
import { AnalyzeContext as AnalyzeContextV2 } from '../../app-v2/context/analyze-context';
import { SearchContext as SearchContextV2 } from '../../app-v2/context/search-context';
import { useViewContext } from '../../app-v2/context/view-context';
import { AnalyzeContext } from '../context/analyze-context';
import { EnsembleContext } from '../context/ensemble-context';
import { LimitContext } from '../context/limit-context';
import { SearchContext } from '../context/search-context';
import {
  FolderSearchPayload,
  getEarliestBatch,
  getFolders,
  getLatestBatch,
  getLimitFolders,
} from '../services/folder';
import { anomalousTags } from '../services/tag';

import { getSubsystemIds } from './anomalies';
import { useTenants } from './tenants';

import { useDebounce } from '@controlrooms/hooks';
import {
  AnomalousTagsPayload,
  AnomalySort,
  CRFolderTags,
  FlattenedFolderTag,
  Folder,
  GenericFolder,
  LimitMatchType,
  MatchType,
  ParentFolder,
  ParentTag,
  Plant,
  PlantWithStates,
  StreamingTimeRange,
  SubSystem,
  SubSystemParentRef,
  System,
  Tag,
  TimeThreshold,
} from '@controlrooms/models';
import { ObjectUtils } from '@controlrooms/utils';
import { IntervalUtil } from '@controlrooms/utils/src/chart-utils/interval';

export const FOLDER_KEY = 'PLANT_FOLDERS';
export const LIMIT_FOLDER_KEY = 'LIMIT_FOLDERS';

export enum FolderSort {
  DEFAULT,
  ALPHA_ASC_NAME,
  ALPHA_DESC_NAME,
  ALPHA_ASC_DESCRIPTION,
  ALPHA_DESC_DESCRIPTION,
}

export const usePlantFolders = (sort: FolderSort = FolderSort.DEFAULT, limit = false) => {
  const plantId = -1; // TODO temporary
  const {
    viewState: { selectedEnsemble: viewEnsemble },
    getQueryKeyWithViewId,
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble ?? appEnsemble;
  const queryKey = limit ? LIMIT_FOLDER_KEY : FOLDER_KEY;
  const payload: FolderSearchPayload = { top_folder: plantId };

  if (selectedEnsemble?.family_id) {
    payload.ensemble_family_id = selectedEnsemble?.family_id;
  }

  const { data, ...rest } = useQuery(
    [getQueryKeyWithViewId(queryKey), selectedEnsemble?.family_id],
    () => getFolders(payload),
    {
      select: (body): Plant => body.result,
      cacheTime: 8 * 60 * 60 * 1000, // 8 hours,
      staleTime: 60 * 60 * 1000, // 1 hour,
    },
  );

  const memoizedSort = memoizerific(3)(
    (plant: Plant | undefined, sort: FolderSort): Plant | undefined => {
      if (plant === undefined) return plant;

      if (sort === FolderSort.DEFAULT) return plant;

      const sorter =
        (sort: FolderSort) =>
        (a: { name: string; description: string }, b: { name: string; description: string }) => {
          if ([FolderSort.ALPHA_ASC_NAME, FolderSort.ALPHA_DESC_NAME].includes(sort)) {
            return sort === FolderSort.ALPHA_ASC_NAME
              ? a.name.localeCompare(b.name)
              : b.name.localeCompare(a.name);
          }
          return sort === FolderSort.ALPHA_ASC_DESCRIPTION
            ? a.description.localeCompare(b.description)
            : b.description.localeCompare(a.description);
        };

      const mapSubsystem = (subsystem: SubSystem, sort: FolderSort): SubSystem => {
        const { tags, subfolders, ...rest } = subsystem;
        const _subfolders = subfolders.map<SubSystem>((s) => mapSubsystem(s, sort));
        return {
          ...rest,
          subfolders: _subfolders,
          tags: tags.sort(sorter(sort)),
        };
      };

      return {
        ...plant,
        subfolders: plant.subfolders
          .map<System<SubSystem>>((system) => ({
            ...system,
            subfolders: system.subfolders
              .map<SubSystem>((subsystem) => mapSubsystem(subsystem, sort))
              .sort(sorter(sort)),
          }))
          .sort(sorter(sort)),
      };
    },
  );

  return { data: data ? memoizedSort(data, sort) : data, ...rest };
};

export const usePlantFoldersWithLimits = (sort: FolderSort = FolderSort.DEFAULT) => {
  const plantId = -1; // TODO temporary
  const {
    viewState: { selectedEnsemble: viewEnsemble },
    getQueryKeyWithViewId,
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble ?? appEnsemble;
  const queryKey = LIMIT_FOLDER_KEY;
  const payload: FolderSearchPayload = { top_folder: plantId };

  if (selectedEnsemble?.family_id) {
    payload.ensemble_family_id = selectedEnsemble?.family_id;
  }

  const { data, ...rest } = useQuery(
    [getQueryKeyWithViewId(queryKey), selectedEnsemble?.family_id],
    () => getLimitFolders(payload),
    {
      select: (body): PlantWithStates => body.result,
      cacheTime: 8 * 60 * 60 * 1000, // 8 hours,
      staleTime: 60 * 60 * 1000, // 1 hour,
    },
  );

  const memoizedSort = memoizerific(3)(
    (plant: Plant | undefined, sort: FolderSort): Plant | undefined => {
      if (plant === undefined) return plant;

      if (sort === FolderSort.DEFAULT) return plant;

      const sorter =
        (sort: FolderSort) =>
        (a: { name: string; description: string }, b: { name: string; description: string }) => {
          if ([FolderSort.ALPHA_ASC_NAME, FolderSort.ALPHA_DESC_NAME].includes(sort)) {
            return sort === FolderSort.ALPHA_ASC_NAME
              ? a.name.localeCompare(b.name)
              : b.name.localeCompare(a.name);
          }
          return sort === FolderSort.ALPHA_ASC_DESCRIPTION
            ? a.description.localeCompare(b.description)
            : b.description.localeCompare(a.description);
        };

      const mapSubsystem = (subsystem: SubSystem, sort: FolderSort): SubSystem => {
        const { tags, subfolders, ...rest } = subsystem;
        const _subfolders = subfolders.map<SubSystem>((s) => mapSubsystem(s, sort));
        return {
          ...rest,
          subfolders: _subfolders,
          tags: tags.sort(sorter(sort)),
        };
      };

      return {
        ...plant,
        subfolders: plant.subfolders
          .map<System<SubSystem>>((system) => ({
            ...system,
            subfolders: system.subfolders
              .map<SubSystem>((subsystem) => mapSubsystem(subsystem, sort))
              .sort(sorter(sort)),
          }))
          .sort(sorter(sort)),
      };
    },
  );

  return {
    data: data
      ? {
          folders_with_tags: memoizedSort(data.folders_with_tags, sort),
          systems_with_states: data.systems_with_states,
        }
      : data,
    ...rest,
  };
};

type FolderFilterFn = (f: Folder<unknown>) => boolean | undefined;

const recursiveFilter = (folders: Array<Folder<unknown>>, filterFn: FolderFilterFn) => {
  return folders.reduce<Array<Folder<unknown>>>((acc, obj) => {
    const subfolders = recursiveFilter(
      (obj.subfolders as unknown as Folder<unknown>[]) || [],
      filterFn,
    );
    if (filterFn(obj) || subfolders.length) {
      acc.push(Object.assign({}, obj, subfolders.length && { subfolders }));
    }
    return acc;
  }, []);
};

export const useSearchStrings = (tags: Tag[], searchTerm: string) => {
  const [filteredStrings, setFilteredStrings] = useState<Tag[]>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 300); // 300ms delay

  useEffect(() => {
    if (!debouncedSearchTerm) {
      setFilteredStrings(tags);
    } else {
      const lowercasedTerm = debouncedSearchTerm.toLowerCase();
      const result = (tags || []).filter(
        (tag: Tag) =>
          tag?.name?.toLowerCase().includes(lowercasedTerm) ||
          tag?.description?.toLowerCase()?.includes(lowercasedTerm) ||
          tag?.uom?.toLowerCase()?.includes(lowercasedTerm),
      );
      setFilteredStrings(result);
    }
  }, [debouncedSearchTerm, tags]);

  return filteredStrings;
};

export const usePlantFoldersWithModel = (sort: FolderSort = FolderSort.DEFAULT) => {
  const { data: plant, ...rest } = usePlantFolders(sort);

  const withModel = useMemo(() => {
    if (!plant) return plant;
    return {
      ...plant,
      subfolders: recursiveFilter(plant.subfolders, (f) => f.has_model || f.has_limits).map<
        Folder<unknown>
      >((s) => {
        return {
          ...s,
          subfolders: ObjectUtils.flatten(s.subfolders as Folder<unknown>[], 'subfolders').filter(
            (f) => f.has_model || f.has_limits,
          ),
        };
      }),
    } as Plant;
  }, [plant]);

  return { data: withModel, ...rest };
};

export interface FlattenedFolder {
  type: 'root' | 'subfolder';
  folder: SubSystem;
  rootFolderId?: number;
}

const flattenFolders = (folders: SubSystem[], rootFolderId?: number): FlattenedFolder[] => {
  return folders.reduce<FlattenedFolder[]>((acc, folder) => {
    if (!rootFolderId) {
      // If there's no rootFolderId, treat this as a root folder
      acc.push({
        type: 'root',
        folder: { ...folder, subfolders: [] }, // Ignore subfolders for this level
      });

      // Now flatten the subfolders and pass the current folder's ID as rootFolderId
      acc.push(...flattenFolders(folder.subfolders, folder.folder));
    } else {
      // Treat this as a subfolder and set the rootFolderId
      acc.push({
        type: 'subfolder',
        folder: { ...folder, subfolders: [] }, // Ignore subfolders for this level
        rootFolderId: rootFolderId,
      });

      // Recursively flatten the subfolders
      acc.push(...flattenFolders(folder.subfolders, rootFolderId));
    }

    return acc;
  }, []);
};

export const flattenFolderTags = (
  folders: SubSystem[],
  parentId: number | undefined,
  level: number,
  isParentVisible: boolean,
  collapsedFolders: Set<number>,
): FlattenedFolderTag[] => {
  const result: FlattenedFolderTag[] = [];

  folders.forEach((folder) => {
    const isCollapsed = collapsedFolders.has(folder.folder);
    const isVisible = isParentVisible; // Folders should always be visible, only descendants hidden

    // Push folder itself (it should always be visible even if collapsed)
    result.push({
      type: parentId === undefined ? 'root' : 'folder',
      item: folder,
      parentId,
      level,
      isVisible,
    });

    // Add tags of the folder itself (both for branch and leaf nodes)
    if (isVisible && folder.tags && folder.tags.length > 0) {
      folder.tags.forEach((tag) => {
        result.push({
          type: 'tag',
          item: tag,
          parentId: folder.folder,
          level: level + 1,
          isVisible, // Tags are visible if the folder is visible
        });
      });
    }

    // Recursively flatten subfolders
    if (!isCollapsed && folder.subfolders && folder.subfolders.length > 0) {
      result.push(
        ...flattenFolderTags(
          folder.subfolders,
          folder.folder,
          level + 1,
          isVisible && !isCollapsed, // Descendants are visible only if the folder is expanded
          collapsedFolders,
        ),
      );
    }
  });

  return result;
};

export const useFlatPlantFoldersWithModel = (sort: FolderSort = FolderSort.DEFAULT) => {
  const { data: plant, ...rest } = usePlantFoldersWithModel(sort);
  if (!plant || !plant.subfolders) {
    return { data: [], ...rest };
  }

  return { data: flattenFolders(plant.subfolders as SubSystem[]), ...rest };
};

const sortFolders = (folderData: Array<CRFolderTags> | undefined, subsystemIds: Array<number>) => {
  folderData &&
    folderData.sort((a: CRFolderTags, b: CRFolderTags) => {
      return subsystemIds.indexOf(a.folder) > subsystemIds.indexOf(b.folder) ? 1 : -1;
    });

  return folderData;
};

const flatten = (subfolders: GenericFolder[], parent: number) => {
  let folders = [] as ParentFolder[];
  subfolders.forEach(({ subfolders, ...rest }) => {
    const withParent = {
      ...rest,
      parentFolder: parent,
    };
    if (withParent.tags) {
      withParent.tags = withParent.tags.map((t) => new ParentTag(t, rest.folder));
    }
    folders.push(withParent as ParentFolder);
    if (subfolders.length) {
      folders = folders.concat(flatten(subfolders, rest.folder));
    }
  });
  return folders;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSearchResults = (sort: FolderSort, dependencies: any[] = []) => {
  const { data: plant } = usePlantFolders(sort);
  const { version } = useVersion();
  const { pinnedTags: appPinnedTags } = useContext(AnalyzeContext);
  const { pinnedTags: V2PinnedTags } = useContext(AnalyzeContextV2);
  const pinnedTags = version === 1 ? appPinnedTags : V2PinnedTags;
  const searchContext = useContext(SearchContext);
  const searchContextV2 = useContext(SearchContextV2);
  const { isSearchWithin, searchTerm, setSearchCount, matchType } =
    version === 1 ? searchContext : searchContextV2;

  const newPlant = JSON.parse(JSON.stringify(plant));

  const lowerSearchTerm = searchTerm?.toLowerCase() as string;

  let currentFolder: number;

  let resultCount = 0;

  const filterMatchString = (tag: Tag) => {
    const tagNameDescription =
      `${tag?.tag_display_name} ${tag?.description} ${tag?.uom}`.toLowerCase();
    const searchTermArray = lowerSearchTerm.trim().split(' ');
    if (matchType === MatchType.ANY) {
      return searchTermArray.some((el) => {
        return tagNameDescription.includes(el);
      });
    } else {
      return searchTermArray.every((el) => {
        return tagNameDescription.includes(el);
      });
    }
  };

  const filterTags = (
    tags: (Tag & { parentPath: string; pinned?: boolean })[],
    parentPath: string,
  ): Tag[] => {
    return tags?.filter((tag) => {
      // Filter pinned tags when 'isSearchWithin' toggle is set
      let isPinnedTag = false;
      if (isSearchWithin) {
        isPinnedTag = pinnedTags.some((pinnedTag) => {
          tag.tag_display_name === pinnedTag?.tag_display_name &&
            pinnedTag.folder === currentFolder &&
            (tag.pinned = true);
          return (
            tag.tag_display_name === pinnedTag?.tag_display_name &&
            pinnedTag.folder === currentFolder
          );
        });
      }
      tag.parentPath = parentPath;
      const isMatchingTag = filterMatchString(tag) && !!(isSearchWithin ? isPinnedTag : true);

      isMatchingTag && resultCount++;

      return isMatchingTag;
    });
  };

  // Recursive function to filter subfolders
  const filterSubfolders = (
    subfolders: (SubSystem & { parentPath?: string })[],
    parentPath?: string,
  ) => {
    return subfolders.filter((subfolder, i) => {
      let tags: Tag[] = [];
      let subsubfolders: SubSystem[] = [];

      parentPath && (subfolder.parentPath = parentPath);

      currentFolder = subfolder.folder;

      tags = filterTags(
        subfolder.tags as (Tag & { parentPath: string })[],
        `${parentPath ? parentPath : ''}/${subfolder.description}` as string,
      );
      parentPath && (subfolders[i].parentPath = parentPath);
      subfolders[i].tags = tags || [];

      if (subfolder.subfolders?.length > 0) {
        subsubfolders = filterSubfolders(
          subfolder.subfolders as SubSystem[] & { parentPath?: string }[],
          `${parentPath ? parentPath + ' / ' : ''}${subfolder.description}`,
        );
        subfolders[i].subfolders = subsubfolders;
      }

      const isMatchingSubfolder: boolean =
        subfolder.description?.toLowerCase().includes(lowerSearchTerm) ||
        subfolder.name?.toLowerCase().includes(lowerSearchTerm);

      isMatchingSubfolder && resultCount++;

      const hasResults: boolean =
        subfolder.description?.toLowerCase().includes(lowerSearchTerm) ||
        subfolder.name?.toLowerCase().includes(lowerSearchTerm) ||
        tags?.length > 0 ||
        subsubfolders.length > 0;

      return hasResults;
    });
  };

  const results = filterSubfolders(newPlant?.subfolders as SubSystem[]);

  useEffect(() => {
    setSearchCount(resultCount);
  }, [setSearchCount, resultCount]);

  const data = useMemo(
    () => results,
    // eslint-disable-next-line
    [plant, searchTerm, matchType, ...(dependencies as [])],
  );

  return data;
};

function isSubSystem(folder: System<SubSystem> | SubSystem): folder is SubSystem {
  return 'tags' in folder;
}

export const useFilteredFolders = (
  subfolders: System<SubSystem>[] | SubSystem[],
): System<SubSystem>[] | SubSystem[] => {
  const searchContext = useContext(SearchContext);
  const searchContextV2 = useContext(SearchContextV2);
  const { version } = useVersion();
  const { isSearchWithin, searchTerm, setSearchCount, matchType } =
    version === 1 ? searchContext : searchContextV2;
  const { pinnedTags: appPinnedTags } = useContext(AnalyzeContext);
  const { pinnedTags: V2PinnedTags } = useContext(AnalyzeContextV2);
  const pinnedTags = version === 1 ? appPinnedTags : V2PinnedTags;
  const _searchTerm = searchTerm.toLowerCase();
  const totalCount = useRef(0);

  const filterMatchString = useCallback(
    (tag: Tag) => {
      const tagNameDescription = `${tag?.tag_display_name || ''} ${tag?.description || ''} ${
        tag?.uom || ''
      }`.toLowerCase();
      const searchTermArray = _searchTerm.trim().split(' ');
      if (matchType === MatchType.ANY) {
        return searchTermArray.some((el) => {
          return tagNameDescription.includes(el);
        });
      } else {
        return searchTermArray.every((el) => {
          return tagNameDescription.includes(el);
        });
      }
    },
    [_searchTerm, matchType],
  );

  const filterFolders = useCallback(
    (folder: SubSystem & { parentPath?: string }, parentPath?: string) => {
      let matches = false;

      // Check folder name and description matches
      if (
        folder?.name.toLowerCase().includes(_searchTerm) ||
        folder?.infra_display_name.toLowerCase().includes(_searchTerm) ||
        (folder?.description && folder?.description.toLowerCase().includes(_searchTerm))
      ) {
        matches = true;
        totalCount.current++; // Increment for each matching folder
      }

      // Filter tags if the folder is a SubSystem
      const isSubfolder = isSubSystem(folder);
      const tags =
        isSubfolder && folder.tags
          ? (folder.tags as (Tag & { parentPath: string; pinned?: boolean })[])
              .filter((tag) => {
                if (isSearchWithin) {
                  return pinnedTags.some((pinnedTag) => {
                    return pinnedTag.tag_display_name === tag?.tag_display_name;
                  });
                } else {
                  return tag;
                }
              })
              .filter((tag) => {
                let isPinnedTag = false;
                if (isSearchWithin) {
                  isPinnedTag = pinnedTags.some((pinnedTag) => {
                    return filterMatchString(pinnedTag);
                  });
                }
                parentPath && (tag.parentPath = parentPath);

                return filterMatchString(tag) && !!(isSearchWithin ? isPinnedTag : true);
              })
          : [];

      parentPath && (folder.parentPath = parentPath);

      if (tags.length > 0) {
        matches = true;
        totalCount.current += tags.length; // Add the number of matching tags
      }

      // Recursively filter subfolders
      const filteredSubfolders = folder.subfolders
        .map((subfolder) =>
          filterFolders(subfolder, `${parentPath ? parentPath + ' / ' : ''}${folder.description}`),
        )
        .filter((subfolder) => subfolder !== null) as typeof folder.subfolders;

      if (filteredSubfolders.length > 0) {
        matches = true;
      }

      if (matches) {
        return { ...folder, tags: isSubSystem(folder) ? tags : [], subfolders: filteredSubfolders };
      }

      return null;
    },
    [_searchTerm, filterMatchString, isSearchWithin, pinnedTags],
  );

  const isEligibleForSearch = useCallback(() => {
    if (matchType === MatchType.ANY) {
      return (
        searchTerm &&
        searchTerm
          .trim()
          .split(' ')
          .every((s) => s.length > 0)
      );
    } else {
      return searchTerm && searchTerm.trim().length > 0;
    }
  }, [matchType, searchTerm]);

  // Use useMemo to avoid recalculating unless subfolders or searchTerm changes
  return useMemo(() => {
    totalCount.current = 0;

    const results = isEligibleForSearch()
      ? (subfolders as SubSystem[])
          .map((subfolder) => filterFolders(subfolder))
          .filter((subfolder) => subfolder !== null)
      : [];
    setSearchCount(totalCount.current); // Update context with final count
    return results as System<SubSystem>[] | SubSystem[];
  }, [isEligibleForSearch, subfolders, setSearchCount, filterFolders]);
};

export const useLimitFilteredFolders = (
  subfolders: System<SubSystem>[] | SubSystem[],
): System<SubSystem>[] | SubSystem[] => {
  const { filterString, limitMatchType, matchType } = useContext(LimitContext);
  const _searchTerm = filterString.toLowerCase();
  const totalCount = useRef(0);

  const filterMatchString = useCallback(
    (tag: Tag) => {
      const tagNameDescription =
        `${tag?.tag_display_name} ${tag?.description} ${tag?.uom}`.toLowerCase();
      const searchTermArray = _searchTerm.trim().split(' ');
      if (matchType === MatchType.ANY) {
        return searchTermArray.some((el) => {
          return tagNameDescription.includes(el);
        });
      } else {
        return searchTermArray.every((el) => {
          return tagNameDescription.includes(el);
        });
      }
    },
    [_searchTerm, matchType],
  );

  const filterFolders = useCallback(
    (folder: SubSystem & { parentPath?: string }, parentPath?: string) => {
      let matches = false;

      // Check folder name and description matches
      if (
        folder?.infra_display_name.toLowerCase().includes(_searchTerm) ||
        (folder?.description && folder?.description.toLowerCase().includes(_searchTerm))
      ) {
        matches = true;
        totalCount.current++; // Increment for each matching folder
      }

      // Filter tags if the folder is a SubSystem
      const isSubfolder = isSubSystem(folder);
      const tags =
        isSubfolder && folder.tags
          ? (folder.tags as (Tag & { parentPath: string; pinned?: boolean })[]).filter((tag) => {
              if (tag.is_state) return true; // Skip if tag is a state
              parentPath && (tag.parentPath = parentPath);
              if (limitMatchType === LimitMatchType.WITH_LIMITS) {
                return tag.limit_ids !== null && tag.limit_ids?.length && filterMatchString(tag);
              }
              return filterMatchString(tag);
            })
          : [];

      parentPath && (folder.parentPath = parentPath);
      // folder.tags = tags || [];

      if (tags.length > 0) {
        matches = true;
        totalCount.current += tags.length; // Add the number of matching tags
      }

      // Recursively filter subfolders
      const filteredSubfolders = folder.subfolders
        .map((subfolder) =>
          filterFolders(subfolder, `${parentPath ? parentPath + ' / ' : ''}${folder.description}`),
        )
        .filter((subfolder) => subfolder !== null) as typeof folder.subfolders;

      if (filteredSubfolders.length > 0) {
        matches = true;
      }

      if (matches) {
        return { ...folder, tags: isSubSystem(folder) ? tags : [], subfolders: filteredSubfolders };
      }

      return null;
    },
    [_searchTerm, filterMatchString, limitMatchType],
  );

  const isEligibleForSearch = useCallback(() => {
    return _searchTerm && _searchTerm.trim().length > 1;
  }, [_searchTerm]);

  const filterWithMatchType = (folders: SubSystem[]): SubSystem[] => {
    function traverseFolders(folders: SubSystem[]): SubSystem[] {
      const result: SubSystem[] = [];

      folders.forEach((folder) => {
        // Recursively process all subfolders first
        const filteredSubfolders = traverseFolders(folder.subfolders || []);

        // Filter tags that have non-empty limit_ids
        const filteredTags =
          folder.tags?.filter((tag) => tag.limit_ids && tag.limit_ids.length > 0) || [];

        // Include the folder if it has relevant tags or its subfolders have relevant content
        if (filteredTags.length > 0 || filteredSubfolders.length > 0) {
          result.push({
            ...folder,
            tags: filteredTags,
            subfolders: filteredSubfolders,
          });
        }
      });

      return result;
    }

    return traverseFolders(folders);
  };

  return useMemo(() => {
    const _subfolders =
      limitMatchType === LimitMatchType.WITH_LIMITS
        ? filterWithMatchType(subfolders as SubSystem[])
        : subfolders;
    if (_searchTerm === '') {
      return _subfolders as SubSystem[];
    }
    // Use useMemo to avoid recalculating unless subfolders or searchTerm changes
    totalCount.current = 0;

    const results = isEligibleForSearch()
      ? (_subfolders as SubSystem[])
          .map((subfolder) => filterFolders(subfolder))
          .filter((subfolder) => subfolder !== null)
      : [];
    // setSearchCount(totalCount.current); // Update context with final count
    return results as System<SubSystem>[] | SubSystem[];
  }, [limitMatchType, subfolders, _searchTerm, isEligibleForSearch, filterFolders]);
};

export const useFlatFolders = (
  sort: FolderSort,
  filter?: {
    folderId?: number;
  },
) => {
  const { data: plant, ...rest } = usePlantFolders(sort);

  const flat = useMemo(() => {
    if (!plant) return plant;
    const flattened = flatten(plant.subfolders, plant.folder);
    if (filter?.folderId) {
      return flattened.filter((f) => f.folder === filter.folderId);
    }
    return flatten(plant.subfolders, plant.folder);
  }, [filter?.folderId, plant]);

  return { data: flat, ...rest };
};

export const ANOMALOUS_FOLDERS_KEY = 'anomalous_folders';
export const useAnomalousTags = (
  { startTime, endTime, streamingTimeInSeconds }: StreamingTimeRange,
  selectedFolders: number[],
  sort: AnomalySort = AnomalySort.SEVERITY,
  showAnalyzeLimits?: boolean,
) => {
  const { currentTenant } = useTenants();
  const { version } = useVersion();
  const { pinnedTags: appPinnedTags = [] } = useContext(AnalyzeContext);
  const { pinnedTags: V2PinnedTags = [] } = useContext(AnalyzeContextV2);
  const pinnedTags = version === 1 ? appPinnedTags : V2PinnedTags;
  const {
    viewState: { selectedEnsemble: viewEnsemble },
    getQueryKeyWithViewId,
  } = useViewContext();
  const { selectedEnsemble: appEnsemble } = useContext(EnsembleContext);
  const selectedEnsemble = viewEnsemble ?? appEnsemble;
  const tenantConfig = currentTenant?.preferences;

  let interval = IntervalUtil.DEFAULT_INTERVAL;
  if (startTime && endTime) {
    interval = IntervalUtil.fromThresholds(
      startTime,
      endTime,
      tenantConfig?.analyzeTimeThresholds as Array<TimeThreshold>,
    );
  }

  const isStreaming = Boolean(streamingTimeInSeconds);
  const formattedStartTime = startTime?.toISOString() ?? undefined;
  const formattedEndTime = endTime?.toISOString() ?? undefined;

  const payload = {
    folders: selectedFolders.length ? selectedFolders : undefined,
    sort_order: sort,
    show_limits: showAnalyzeLimits,
  } as AnomalousTagsPayload;

  if (isStreaming) {
    payload.preset = streamingTimeInSeconds;
  } else {
    payload.start_time = formattedStartTime;
    payload.end_time = formattedEndTime;
  }

  if (selectedEnsemble?.family_id) {
    payload.ensemble_family_id = selectedEnsemble?.family_id;
  }

  const { data, ...rest } = useQuery(
    [getQueryKeyWithViewId(ANOMALOUS_FOLDERS_KEY), payload],
    () => anomalousTags(payload, selectedEnsemble?.family_id as string),
    {
      select: (r) => r.result,
      enabled: ObjectUtils.areNonNull(
        payload as unknown as Record<string, unknown>,
        Object.keys(payload),
      ),
      retry: isStreaming ? 1 : undefined,
      refetchInterval: isStreaming ? IntervalUtil.refetchMsFromInterval(interval) : false,
    },
  );

  const { data: plant } = usePlantFoldersWithModel();
  const subsystemIds = useMemo(() => getSubsystemIds(plant), [plant]);

  if (data && subsystemIds) {
    sortFolders(data, subsystemIds);
  }

  const merged = useMemo(() => {
    const pinnedMap = pinnedTags.reduce<Record<number, string[]>>((acc, { folder, name }) => {
      if (acc[folder]) {
        acc[folder].push(name);
      } else {
        acc[folder] = [name];
      }
      return acc;
    }, {});

    if (!data && pinnedMap !== null && pinnedMap !== undefined) {
      // Return pinned only
      return Object.entries(pinnedMap).map(([folder, tags]) => ({
        folder: Number(folder),
        tags,
      }));
    }

    const pinnedFolders = pinnedMap ? Object.keys(pinnedMap).map((f) => Number(f)) : [];

    // Merge pinned with data
    const _data = structuredClone(data) as CRFolderTags[];
    let idx = 0;
    pinnedFolders.forEach((f) => {
      const found = _data.find((d) => d.folder === f);
      if (found) {
        found.tags = [...pinnedMap[f], ...found.tags.filter((t) => !pinnedMap[f].includes(t))];
      } else {
        _data.splice(idx++, 0, { folder: f, tags: pinnedMap[f] });
      }
    });

    return _data;
  }, [data, pinnedTags]);

  return { data: merged, ...rest, isSuccess: rest.isSuccess || merged?.length } as UseQueryResult<
    CRFolderTags[]
  >;
};

export const useSystemsById = () => {
  const { data: plant, ...rest } = usePlantFolders();

  const byId = useMemo(() => {
    if (plant) {
      return plant.subfolders.reduce((acc: Record<number, System<SubSystem>>, system) => {
        acc[system.folder] = system;
        return acc;
      }, {});
    }
    return {};
  }, [plant]);

  return { ...rest, data: byId };
};

const subSystemsAsRecord = (obj: SubSystem[]): Record<string, SubSystemParentRef> => {
  const newRecord = {} as Record<string, SubSystemParentRef>;

  obj.map((v) => {
    newRecord[v.folder] = v;

    if (v.subfolders.length > 0) {
      Object.assign(newRecord, subSystemsAsRecord(v.subfolders));
    }
  });

  return newRecord;
};

export const useSubSystemsById = () => {
  const { data: plant, ...rest } = usePlantFolders();

  const byId = useMemo(() => {
    if (plant) {
      return plant.subfolders.reduce((acc: Record<number, SubSystemParentRef>, system) => {
        const current = system.subfolders.reduce(
          (subAcc: Record<number, SubSystemParentRef>, subsystem) => {
            subAcc[subsystem.folder] = subsystem;
            subAcc[subsystem.folder].parentName = system.name;
            subAcc[subsystem.folder].parentId = system.folder;

            // Recursively flatten all subsystems in to 'byId' record
            const flattenedSubSystems = subSystemsAsRecord(subAcc[subsystem.folder].subfolders);
            return Object.assign(subAcc, flattenedSubSystems);
          },
          {},
        );

        return Object.assign(acc, current);
      }, {});
    }
    return {};
  }, [plant]);

  return { ...rest, data: byId };
};

export const useSubsystem = (subsystem: number) => {
  const { data: byId, ...rest } = useSubSystemsById();

  return { ...rest, data: byId?.[subsystem] };
};

const getTagsObj = (system: SubSystem) => {
  return system.tags?.reduce((tagAcc: Record<string, Tag>, tag) => {
    tagAcc[tag.name] = tag;
    return tagAcc;
  }, {});
};

export const _useTagsByName = () => {
  const { data: plant, ...rest } = usePlantFolders();

  const byName = useMemo(() => {
    if (plant) {
      return plant.subfolders.reduce((systemAcc: Record<string, SubSystem>, system) => {
        const currentSystem = system.subfolders.reduce(
          (subsystemAcc: Record<number, SubSystem>, subsystem) => {
            let currentSubSystem: Record<string, Tag>;

            if (subsystem.subfolders.length)
              currentSubSystem = subsystem.subfolders.reduce(
                (_: Record<string, Tag>, secondSubFolder) =>
                  Object.assign(subsystem, getTagsObj(secondSubFolder)),
                {},
              );
            else currentSubSystem = getTagsObj(subsystem);

            return Object.assign(subsystemAcc, currentSubSystem);
          },
          {},
        );
        return Object.assign(systemAcc, currentSystem);
      }, {});
    }
    return {};
  }, [plant]) as unknown as Record<string, Tag>;

  return { ...rest, data: byName };
};

export const useTagsByName = () => {
  const { data: plant, ...rest } = usePlantFolders();

  const tagsByName = useMemo(() => {
    if (!plant) return {} as Record<string, Tag>;

    const tagMap: Record<string, Tag> = {};

    const traverseFolder = (folder: SubSystem | Plant) => {
      if ('tags' in folder && Array.isArray(folder.tags)) {
        folder.tags.forEach((tag) => {
          tagMap[tag.name] = tag;
        });
      }

      if (Array.isArray(folder.subfolders)) {
        folder.subfolders.forEach((subfolder) => traverseFolder(subfolder));
      }
    };

    traverseFolder(plant);

    return tagMap;
  }, [plant]);

  return { ...rest, data: tagsByName };
};

export const useFolderTagsByLimit = () => {
  const { data, ...rest } = usePlantFoldersWithLimits();
  const plant = data?.folders_with_tags;

  // Returns folderTags of type Record<number, Tag[]> by traversing through subfolders of the folder tree and store all tags in a folder for easy access
  const folderTags = useMemo(() => {
    if (!plant) return {} as Record<number, Tag[]>; // Return empty object if plant is not available

    const folderTags: Record<number, Tag[]> = {};

    const traverseFolder = (folder: SubSystem | Plant) => {
      if ('tags' in folder && Array.isArray(folder.tags)) {
        folderTags[folder.folder] = folder.tags;
      }

      if (Array.isArray(folder.subfolders)) {
        folder.subfolders.forEach((subfolder) => traverseFolder(subfolder));
      }
    };
    traverseFolder(plant);
    return folderTags;
  }, [plant]);

  return { ...rest, data: folderTags };
};

export const useLatestData = (isBatch: boolean | undefined, hasStatus: boolean | undefined) =>
  useQuery('data-latest', getLatestBatch, {
    enabled: Boolean(isBatch && hasStatus),
    select: ({ result }) => result,
  });

export const useEarliestData = (isBatch: boolean | undefined, hasStatus: boolean | undefined) =>
  useQuery('data-latest', getEarliestBatch, {
    enabled: Boolean(isBatch && hasStatus),
    select: ({ result }) => result,
  });
