import dayjs from 'dayjs';
import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';

import { useViewContext } from './view-context';

import {
  CRFolderTags,
  ParentTag,
  Tag,
  TimeRanges,
  TimeSearchResult,
  TimeSelection,
  TrendSearchData,
  ViewType,
} from '@controlrooms/models';

interface ContextProps {
  event: SearchEvent;
  setEvent: Dispatch<SetStateAction<SearchEvent>>;
  selectedEvents: TimeSearchResult[];
  setSelectedEvents: Dispatch<SetStateAction<TimeSearchResult[]>>;
  selectedTab: TimeSearchResult | undefined;
  setSelectedTab: Dispatch<SetStateAction<TimeSearchResult | undefined>>;
  timeRange: string | undefined;
  setTimeRange: Dispatch<SetStateAction<string | undefined>>;
  selectedTrendSearchFolders: number[];
  setSelectedTrendSearchFolders: Dispatch<SetStateAction<number[]>>;
  timeSearchTimeSelection: TimeSelection;
  setTimeSearchTimeSelection: Dispatch<SetStateAction<TimeSelection>>;
  relatedTags: CRFolderTags[];
  setRelatedTags: Dispatch<SetStateAction<CRFolderTags[]>>;
  pinnedTags: ParentTag[];
  setPinnedTags: Dispatch<SetStateAction<ParentTag[]>>;
}

const defaultTimeSelections: TimeSelection = {
  startTime: dayjs().subtract(4, 'hour'),
  endTime: dayjs(),
  timezone: dayjs.tz.guess(), // Default to user timezone
  timeRange: TimeRanges.CUSTOM,
  streamingTimeInSeconds: undefined,
  nowSelected: false,
};

const defaultState = {
  event: {} as SearchEvent,
  setEvent: () => null,
  selectedEvents: [],
  setSelectedEvents: () => null,
  selectedTab: undefined,
  setSelectedTab: () => null,
  timeRange: undefined,
  setTimeRange: () => null,
  selectedTrendSearchFolders: [],
  setSelectedTrendSearchFolders: () => null,
  timeSearchTimeSelection: {} as TimeSelection,
  setTimeSearchTimeSelection: () => null,
  relatedTags: [],
  setRelatedTags: () => [],
  pinnedTags: [],
  setPinnedTags: () => null,
};

export type SearchEvent = {
  id?: number;
  startTime: string;
  endTime: string;
  folder: number;
  tag: Tag;
  tagName: string;
};

export const TimeSearchContext = createContext<ContextProps>(defaultState);

export const dateFormat = 'YYYY-MM-DDTHH:mm:ss[Z]';

const TimeSearchContextProvider: React.FC = ({ children }) => {
  const { viewState } = useViewContext();
  const timezone = viewState.timeSelection?.timezone;
  const {
    startTime,
    endTime,
    timeRange: timeSelection,
    tag,
    folder,
  } = viewState.view[ViewType.ANALYZE].trendSearchData as TrendSearchData;
  const defaultTab = useMemo(() => {
    return {
      start_time: startTime,
      end_time: endTime,
      dtw_distance: 0,
      good_search_result: false,
      window_data: [0],
      event_type: 'default',
    };
  }, [startTime, endTime]);
  const [event, setEvent] = useState<SearchEvent>({
    id: 0,
    startTime: startTime,
    endTime: endTime,
    folder: folder,
    tagName: tag?.name,
    tag,
  });
  const [selectedEvents, setSelectedEvents] = useState<TimeSearchResult[]>([]);
  const [selectedTab, setSelectedTab] = useState<TimeSearchResult | undefined>(defaultTab);
  const [timeRange, setTimeRange] = useState<string | undefined>(timeSelection);
  const [selectedTrendSearchFolders, setSelectedTrendSearchFolders] = useState<number[]>([folder]);
  const [timeSearchTimeSelection, setTimeSearchTimeSelection] = useState<TimeSelection>({
    ...defaultTimeSelections,
    timezone: timezone,
    startTime: dayjs(startTime),
    endTime: dayjs(endTime),
  });
  const [relatedTags, setRelatedTags] = useState<CRFolderTags[]>([
    {
      folder,
      tags: [tag?.name],
    },
  ]);
  const [pinnedTags, setPinnedTags] = useState<ParentTag[]>([
    {
      description: tag?.description,
      name: tag?.name,
      tag_display_name: tag?.tag_display_name,
      uom: tag.uom,
      folder: folder,
    },
  ]);

  const appState = useMemo(
    () => ({
      event,
      setEvent,
      selectedEvents,
      setSelectedEvents,
      selectedTab,
      setSelectedTab,
      timeRange,
      setTimeRange,
      selectedTrendSearchFolders,
      setSelectedTrendSearchFolders,
      timeSearchTimeSelection,
      setTimeSearchTimeSelection,
      relatedTags,
      setRelatedTags,
      pinnedTags,
      setPinnedTags,
    }),
    [
      event,
      selectedEvents,
      selectedTab,
      timeRange,
      selectedTrendSearchFolders,
      timeSearchTimeSelection,
      relatedTags,
      pinnedTags,
    ],
  );

  return <TimeSearchContext.Provider value={appState}>{children}</TimeSearchContext.Provider>;
};

export default TimeSearchContextProvider;
