import { Folder, SubSystem, Tag } from '../api/folder';

export interface SubSystemAnomalous extends SubSystem {
  isAnomalous: boolean;
}

export interface SubSystemParentRef extends SubSystem {
  infra_type_id?: number;
  parentName?: string;
  parentId?: number;
}

export interface GenericFolder extends Folder<GenericFolder[]> {
  tags?: Tag[];
}

export interface ParentFolder extends GenericFolder {
  parentFolder: number;
  tags?: ParentTag[];
  infra_type_id?: number;
}

export class ParentTag implements Tag {
  description: string;
  tag_display_name: string;
  name: string;
  uom: string;
  folder: number;

  constructor(init: Tag, folder: number) {
    this.description = init.description || '';
    this.name = init.name || '';
    this.tag_display_name = init.tag_display_name || '';
    this.uom = init.uom;
    this.folder = folder;
  }
}

export enum MatchType {
  ANY = 'any',
  ALL = 'all',
}

export enum LimitMatchType {
  ALL = 'ALL',
  WITH_LIMITS = 'WITH_LIMITS',
}
