export const alertConditionLabels: { [key in string]: string } = {
  anomalies_detected: 'Anomalies',
  iow_limit_exceeded: 'Limits',
};

export enum TabState {
  ALERT = 'ALERT',
  WEBHOOK = 'WEBHOOK',
}

export interface TeamChannel {
  channel_name: string;
  channel_id?: string;
  team_name?: string;
  team_id?: string;
  email?: string;
  webhook?: string;
}

interface TeamsDestination {
  selected: boolean | null;
  teams_channels: TeamChannel[];
}

interface EmailDestination {
  selected: boolean;
  emails: string[];
  email_action?: string;
}

// interface PhoneDestination {
//   selected: boolean;
//   phone_nos: string[];
// }

export interface AlertViewSummary {
  description: string;
  name: string;
  infra_display_name: string | null;
  tag_display_name: string | null;
  target_display_name: string | null;
  pinned_tag_count?: number | null;
  system_count?: number | null;
  system_name?: string;
  tag_count?: number | null;
  tag_description?: string;
}

interface IAlertCondition {
  [key: string]: boolean | number | null;
}
export interface AlertCondition extends IAlertCondition {
  selected: boolean;
  duration: number | null;
  snooze_duration: number | null;
}

export interface AlertConditions {
  [key: string]: AlertCondition;
}

export interface AlertDestination {
  email_destination: EmailDestination;
  teams_destination: TeamsDestination;
}

export interface Alert {
  alert_conditions: AlertConditions;
  alert_destination: AlertDestination;
  alert_view_summary: AlertViewSummary | null;
  created_by?: string;
  disabled: boolean | null;
  emailInput?: string;
  ensemble_family_id?: string;
  id?: number;
  isSelected?: boolean;
  target_system?: string;
  target_name: string | null;
  target_display_name: string | null;
  tag_description?: string;
  target_type: string;
  target_id?: string;
  user_email?: string;
  user_id?: string;
}

export interface SearchSuggestion {
  category?: string;
  search_result: string;
  search_value?: string;
  target_id?: string;
  user_id?: string;
  type?: string;
}

export interface AlertSearchResult {
  [key: string]: SearchSuggestion[];
}

export interface AlertSearchCategory {
  label: string;
  value: string;
  checked?: boolean;
}

export interface TeamsWebhook {
  channel_name: string;
  webhook_url: string;
}
