import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';

import { InviteModal } from '../../../app/components/account/invite-modal';
import { UserPreferences } from '../../../app/components/account/user-preferences-modal';
import { Role } from '../../../app/constants/auth';
import { Paths, QueryParams } from '../../../app/constants/paths';
import { AuthorizationContext } from '../../../app/context/authorization-context';
import { useTenant } from '../../../app/context/tenant-context';
import UserPreferencesContextProvider from '../../../app/context/user-preferences-context';
import { useTheme } from '../../../app/hooks/accounts';
import {
  DISCRETE_TAGS_DATA_KEY,
  FOLDER_DATA_KEY,
  FOLDER_DISCRETE_DATA_KEY,
  FOLDER_STRING_DATA_KEY,
  NUMERIC_TAGS_DATA_KEY,
  STRING_TAGS_DATA_KEY,
} from '../../../app/hooks/tags';
import { useLayoutContext } from '../../context/layout-context';
import { useViewContext } from '../../context/view-context';
import { useGetTenantConfig } from '../../hooks/tenants';

import alerts from './alerts.svg';
import assess from './assess.svg';
import investigate from './investigate.svg';
import limits from './limits.svg';
import monitor from './monitor.svg';
// import share from './share.svg';
import {
  Invite,
  Menu,
  MenuItem,
  Nav,
  NavMenuItem,
  Profile,
  StyledSidebar,
  TooltipText,
} from './style';

import { Li, Modal, ModalContents, ModalOpenButton, Tooltip } from '@controlrooms/components';
import { useClickOutside } from '@controlrooms/hooks';
import { ViewType } from '@controlrooms/models';

const Ul = styled.ul<{ isOpen: boolean }>`
  position: fixed;
  bottom: 0;
  left: 55px;
  flex-direction: column;
  justify-content: flex-start;
  width: 211px;
  padding: 0;
  margin-top: 25px;
  background-color: ${({ theme }) => theme.modal.contentBackgroundColor};
  list-style: none;
  border: 1px solid black;
  border-radius: 4px;
  ${(props) => {
    if (!props.isOpen) {
      return `
        display: none;
      `;
    }
  }}
`;

const StyledUserDropdown = styled(Ul)`
  li {
    margin: 0;
  }
  li > button {
    color: ${({ theme }) => theme.modal.panel.color};
    cursor: pointer;
    border: 0;
    background: transparent;
  }
`;

const Sidebar = () => {
  const { activeModes, setActiveModes, activeView } = useLayoutContext();
  const { setViewState, getQueryKeyWithViewId } = useViewContext();
  const { data: tenantConfig } = useGetTenantConfig();
  const [searchParams, setSearchParams] = useSearchParams();

  const { logout, user } = useAuth0();
  const [, themeName] = useTheme();

  const { tenant } = useTenant();
  const navigate = useNavigate();
  const [isUserSelectOpen, setIsUserSelectOpen] = useState(false);
  const userUlRef = useRef(null);
  useClickOutside(userUlRef, () => setIsUserSelectOpen(false));
  const { canUserReadAlert } = useContext(AuthorizationContext);

  const { pathname } = useLocation();
  const isView = matchPath(Paths.VIEWS, pathname);
  const queryClient = useQueryClient();

  const profileInitial = useMemo(() => {
    const name = user?.name || '';
    const initial =
      name
        .match(/(\b\S)?/g)
        ?.join('')
        ?.match(/(^\S|\S$)?/g)
        ?.join('')
        .toUpperCase() || 'U';
    return initial;
  }, [user?.name]);

  const clearCachedQueries = useCallback(() => {
    queryClient.removeQueries(getQueryKeyWithViewId(FOLDER_DATA_KEY));
    queryClient.removeQueries(getQueryKeyWithViewId(NUMERIC_TAGS_DATA_KEY));
    queryClient.removeQueries(getQueryKeyWithViewId(FOLDER_STRING_DATA_KEY));
    queryClient.removeQueries(getQueryKeyWithViewId(STRING_TAGS_DATA_KEY));
    queryClient.removeQueries(getQueryKeyWithViewId(FOLDER_DISCRETE_DATA_KEY));
    queryClient.removeQueries(getQueryKeyWithViewId(DISCRETE_TAGS_DATA_KEY));
  }, [getQueryKeyWithViewId, queryClient]);

  const handleModeChange = (view: ViewType) => {
    if (activeModes[activeView] === ViewType.ANALYZE) {
      clearCachedQueries();
    }
    if (activeModes[activeView] === ViewType.ANALYZE && view === ViewType.ANALYZE) {
      clearCachedQueries();
    }

    if (!isView) {
      navigate(
        {
          pathname: generatePath(Paths.VIEWS, { tenantId: tenant }),
        },
        { state: { viewMode: view } },
      );
    }
    setActiveModes((prev) => {
      return {
        ...prev,
        [activeView]: view,
      };
    });
    setViewState((prev) => {
      return {
        ...prev,
        isDirty: true,
      };
    });
  };

  return (
    <StyledSidebar>
      {/* <Logo>
        <img src={logo} />
      </Logo> */}
      <Nav>
        <Menu>
          <MenuItem
            className={isView && activeModes[activeView] === ViewType.MONITOR ? 'active' : ''}
            onClick={() => handleModeChange(ViewType.MONITOR)}
          >
            <img src={monitor} />
            <TooltipText>Monitor</TooltipText>
          </MenuItem>
          <MenuItem
            className={isView && activeModes[activeView] === ViewType.ANALYZE ? 'active' : ''}
            onClick={() => handleModeChange(ViewType.ANALYZE)}
          >
            <img src={assess} />
            <TooltipText>Assess</TooltipText>
          </MenuItem>
          <MenuItem
            className={isView && activeModes[activeView] === ViewType.INVESTIGATE ? 'active' : ''}
            onClick={() => handleModeChange(ViewType.INVESTIGATE)}
          >
            <img src={investigate} />
            <TooltipText>Investigate</TooltipText>
          </MenuItem>
          {canUserReadAlert && (
            <NavMenuItem to={generatePath(Paths.MANAGE_ALERTS, { tenantId: tenant.toString() })}>
              <img src={alerts} />
              <TooltipText>Alert Manager</TooltipText>
            </NavMenuItem>
          )}
          {canUserReadAlert && (
            <NavMenuItem to={generatePath(Paths.MANAGE_LIMITS, { tenantId: tenant.toString() })}>
              <img src={limits} />
              <TooltipText>Limit Manager</TooltipText>
            </NavMenuItem>
          )}
        </Menu>
      </Nav>
      <Nav style={{ flex: 'none' }}>
        {tenantConfig?.showInvite && (
          <Modal open={Boolean(searchParams.get(QueryParams.INVITE))}>
            <ModalOpenButton>
              <Tooltip
                label="Invite a Colleague"
                place="right-end"
                theme={themeName.toLowerCase()}
                delay="100"
              >
                <Invite
                  buttonSize="large"
                  style={{
                    backgroundColor: 'transparent',
                    width: '100%',
                    justifyContent: 'space-evenly',
                  }}
                  buttonType="icon"
                  iconName="export"
                  className={isView && activeModes[activeView] === ViewType.MONITOR ? 'active' : ''}
                  onClick={() => {
                    const params = new URLSearchParams();
                    params.append(QueryParams.INVITE, 'true');
                    params.append(QueryParams.ROLE, Role.OBSERVER);
                    return setSearchParams(params);
                  }}
                />
              </Tooltip>
            </ModalOpenButton>
            <ModalContents
              closeButtonCallback={() => {
                const _params = new URLSearchParams(searchParams);
                _params.delete(QueryParams.INVITE);
                _params.delete(QueryParams.ROLE);
                setSearchParams(_params);
              }}
              title="Invite"
            >
              <InviteModal
                sandbox={false}
                onSubmit={() => {
                  const _params = new URLSearchParams(searchParams);
                  _params.delete(QueryParams.INVITE);
                  _params.delete(QueryParams.ROLE);
                  setSearchParams(_params);
                }}
              />
            </ModalContents>
          </Modal>
        )}

        <Profile
          buttonSize="small"
          buttonType="text"
          onClick={(evt) => {
            evt.stopPropagation();
            setIsUserSelectOpen((prevState) => !prevState);
          }}
        >
          {profileInitial}
        </Profile>
        <StyledUserDropdown isOpen={isUserSelectOpen} ref={userUlRef} className="user-dropdown">
          <Li data-testid="user-preferences" className="user-prefs-menu-item">
            <UserPreferencesContextProvider>
              <Modal>
                <ModalOpenButton>
                  <button>User Preferences</button>
                </ModalOpenButton>
                <ModalContents title="User Preferences" closeButtonText="Close">
                  <UserPreferences />
                </ModalContents>
              </Modal>
            </UserPreferencesContextProvider>
          </Li>
          <Li data-testid="log-out" id="logout" className="logout">
            <button
              onClick={() => {
                sessionStorage.clear();
                logout({ logoutParams: { returnTo: window.location.origin } });
              }}
            >
              Log Out
            </button>
          </Li>
        </StyledUserDropdown>
      </Nav>
    </StyledSidebar>
  );
};

export default Sidebar;
