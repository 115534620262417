import Request from './request';

import {
  Alert,
  AlertSearchResult,
  AlertViewSummary,
  ApiListResult,
  ApiResult,
  RegisteredChannel,
  RegisteredChannelResult,
  TeamsWebhook,
} from '@controlrooms/models';

const SERVICE_ROOT = '/alert-manager/v1';
const RESOURCE_ROOT = `${SERVICE_ROOT}/rules`;

export interface AlertSummaryParams {
  targetType: string;
  targetId: string | number;
}

export interface MSTeamsChannel {
  targetType: string;
  targetId: string | number;
}

export interface EnsembleByReference {
  rule_id: number;
  alert_id: number;
  user_group_id: string;
  family_id: string;
  is_anomaly_visible: boolean;
  alert_type: string;
}

export const createAlert = (data: Partial<Alert>): Promise<ApiResult<{ message: string }>> =>
  Request.post(`${RESOURCE_ROOT}`, data);

export const getAlerts = (queryString: string): Promise<ApiResult<{ alert_list: Alert[] }>> =>
  Request.get(`${RESOURCE_ROOT}?${queryString}`).then((result) => result);

export const getAlertByID = (id: number): Promise<ApiResult<Alert>> =>
  Request.get(`${RESOURCE_ROOT}/${id}`).then((result) => result);

export const getAlertByTargetID = (params: AlertSummaryParams): Promise<ApiResult<Alert>> =>
  Request.get(`${RESOURCE_ROOT}/target_type/${params.targetType}/target/${params.targetId}`).then(
    (result) => result,
  );

export const getAlertSummary = (params: AlertSummaryParams): Promise<ApiResult<AlertViewSummary>> =>
  Request.get(
    `${SERVICE_ROOT}/view-summary?target_type=${params.targetType}&target_id=${params.targetId}`,
  ).then((result) => result);

export const getAlertSearchSuggestion = ({
  searchText,
  maxLimit = 3,
}: {
  searchText: string;
  maxLimit?: number;
}): Promise<ApiResult<AlertSearchResult>> =>
  Request.get(
    `${SERVICE_ROOT}/search_rules?search_text=${encodeURIComponent(
      searchText,
    )}&max-limit=${maxLimit}`,
  ).then((result) => result);

export const updateAlert = (data: Partial<Alert>): Promise<ApiResult<{ message: string }>> =>
  Request.put(`${RESOURCE_ROOT}/${data?.id}`, data);

export const patchAlert = (data: {
  rule_ids: number[];
  rule_info: Partial<Alert>;
}): Promise<ApiResult<{ message: string }>> => Request.patch(`${RESOURCE_ROOT}/bulk_update`, data);

export const deleteAlert = (id: number): Promise<ApiResult<{ message: string }>> =>
  Request.delete(`${RESOURCE_ROOT}/${id}`);

export const deleteAlertByCustomViewId = (
  params: AlertSummaryParams,
): Promise<ApiResult<{ message: string }>> =>
  Request.delete(`${RESOURCE_ROOT}/target_type/${params.targetType}/target/${params.targetId}`);

export const deleteMultipleAlert = (rule_ids: number[]): Promise<ApiResult<{ message: string }>> =>
  Request.delete(`${RESOURCE_ROOT}`, {}, { rule_ids });

export const registerTeamsWebHook = (
  data: TeamsWebhook[],
): Promise<ApiResult<{ message: string }>> => Request.post(`${SERVICE_ROOT}/teams/channels`, data);

export const delteTeamsWebHook = (
  channel_name: string,
): Promise<ApiResult<{ message: string }>> => {
  return Request.delete(`${SERVICE_ROOT}/teams/channels`, {}, [
    {
      channel_name,
      webhook_url: '',
    },
  ]);
};

export const testTeamsWebHook = (data: {
  channel_name: string;
  webhook_url: string;
}): Promise<ApiResult<{ message: string }>> =>
  Request.post(`${SERVICE_ROOT}/teams/test-webhook`, data);

export const getRegisteredChannels = (): Promise<ApiListResult<RegisteredChannel>> =>
  Request.get(`${SERVICE_ROOT}/teams/channels`).then(
    (result) => new RegisteredChannelResult(result),
  );

export const getEnsembleIdByReference = ({
  reference,
  startTime,
  endTime,
}: {
  reference: string | null;
  startTime: string | null;
  endTime: string | null;
}): Promise<ApiResult<EnsembleByReference | null>> =>
  Request.get(
    `${SERVICE_ROOT}/trace?reference=${reference}&start_time=${startTime}&end_time=${endTime}`,
  );
