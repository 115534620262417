import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export class TimeUtils {
  // Converts a given time to UTC time
  // If time is 2023-08-01T10:30:00+05:00, the output will be 2023-08-01T05:30:00Z
  public static toUtc(time: dayjs.ConfigType): Dayjs {
    if (!dayjs.isDayjs(time)) {
      return dayjs(time).utc();
    }
    return time.utc();
  }

  // Converts a given time to a specific timezone
  // If time is 2023-08-01T10:30:00+05:00, timezone is 'America/New_York' and keepLocalTime is false, the output will be 2023-08-01T01:30:00-04:00
  // If time is 2023-08-01T10:30:00+05:00, timezone is 'America/New_York' and keepLocalTime is true, the output will be 2023-08-01T10:30:00-04:00
  public static toTimezone(time: dayjs.ConfigType, timezone: string, keepLocalTime = false): Dayjs {
    if (!dayjs.isDayjs(time)) {
      return dayjs(time).tz(timezone, keepLocalTime);
    }
    return time.tz(timezone, keepLocalTime);
  }

  public static prepareTimeString(time: dayjs.Dayjs, timezone: string) {
    return `${TimeUtils.toTimezone(time, timezone).format('MMM DD YY, HH:mm:ss z')}`;
  }

  // Converts a given time to the local time
  // If time is 2023-08-01T10:30:00+05:00 and the local timezone is '+03:00', the output will be 2023-08-01T08:30:00+03:00
  public static toLocal(time: dayjs.ConfigType): Dayjs {
    if (!dayjs.isDayjs(time)) {
      return dayjs(time).local();
    }
    return time.local();
  }

  // Returns the current UTC time
  // If the current time is 2023-08-01T10:30:00+03:00, the output will be 2023-08-01T07:30:00Z
  public static utcNow(): Dayjs {
    return dayjs.utc();
  }

  // Returns the current time in a specific timezone and format
  // If the current time is 2023-08-01T10:30:00+03:00, zone is 'America/New_York' and format is 'YYYY-MM-DD HH:mm:ss', the output will be '2023-08-01 04:30:00'
  public static zoneFormat(zone: string, format: string): string {
    return dayjs().tz(zone).format(format);
  }

  // Converts milliseconds to seconds
  // If milliseconds is 5000, the output will be 5
  public static millisecondsToSeconds(milliseconds: number): number {
    return Math.floor(milliseconds / 1000);
  }

  public static stringToISO8601WithOffset(timeString: string, offset: string) {
    const time = dayjs(timeString);
    const formattedTime = time.utcOffset(offset).format();
    return formattedTime;
  }

  public static datetimeRangeString(startTime: string, endTime: string, tz = '') {
    if (isNaN(Number(startTime))) throw new Error('Invalid date');
    const start = dayjs(Number(startTime)).tz(tz);
    const end = dayjs(Number(endTime)).tz(tz);

    // Format the date and time
    const dateFormat = 'MMM DD';
    const monthFormat = 'MMM';
    const dayFormat = 'DD';
    const timeFormat = 'HH:mm';

    let dateRange;
    // Check if the dates are the same
    if (start.isSame(end, 'date')) {
      dateRange = start.format(dateFormat);
    }
    // Check if the dates are in the same month
    else if (start.format(monthFormat) === end.format(monthFormat)) {
      dateRange = `${start.format(monthFormat)} ${start.format(dayFormat)} - ${end.format(
        dayFormat,
      )}`;
    } else {
      dateRange = `${start.format(dateFormat)} - ${end.format(dateFormat)}`;
    }

    // Format the time range
    const timeRange = `${start.format(timeFormat)} - ${end.format(timeFormat)}`;

    return { dateRange, timeRange };
  }

  public static trendSearchDateTimeRange(startIsoString: string, endIsoString: string, tz = '') {
    // Convert ISO date strings to Day.js objects
    const start = dayjs(startIsoString).tz(tz);
    const end = dayjs(endIsoString).tz(tz);

    const dateFormat = `MMM DD 'YY`;
    const timeFormat = 'HH:mm';

    let dateRange;
    // Check if the dates are the same
    if (start.isSame(end, 'date')) {
      dateRange = start.format(dateFormat);
    } else if (start.isSame(end, 'month') && start.isSame(end, 'year')) {
      // Check if the dates are in the same month and year
      dateRange = `${start.format('MMM DD')} - ${end.format(`DD 'YY`)}`;
    } else {
      dateRange = `${start.format(dateFormat)} - ${end.format(dateFormat)}`;
    }
    // Format the time range

    const timeRange = `${start.format(timeFormat)} - ${end.format(timeFormat)}`;

    return `${dateRange}, ${timeRange}`;
  }
}
