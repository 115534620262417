import { useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useViewContext } from '../../app-v2/context/view-context';
import { Role } from '../constants/auth';
import { AuthorizationContext } from '../context/authorization-context';
import {
  createLabelType,
  updateLabelType,
  deleteLabelType,
  createLabeledEvent,
  updateLabeledEvent,
  getLabelType,
  getLabeledEventsByLabelTypeId,
  getFilters,
  updateFilters,
  getLabeledEventsInRange,
  getLabelTypes,
  getLabeledEvents,
  deleteLabeledEvent,
} from '../services/accounts-customize';

import { LabeledEvent, LabeledEventType } from '@controlrooms/models';

// TODO - move to utils
export const getTypeLabelsById = (arr: LabeledEventType[], labelIds: number[]): string | null => {
  const labels: string[] = [];

  for (const obj of arr) {
    if (labelIds.includes(obj.label_type_id as number)) {
      labels.push(obj.type_name as string);
    }

    // If the object has child_types, look inside them.
    if (obj.child_types && obj.child_types.length > 0) {
      const childLabels = obj.child_types
        .map((child) => getTypeLabelsById([child], labelIds))
        .filter(Boolean);
      if (childLabels.length) {
        labels.push(`${obj.type_name}: ${childLabels.join(', ')}`);
      }
    }
  }

  return labels.join(', ');
};

export const useLabelsAPI = () => {
  const queryClient = useQueryClient();
  const { getQueryKeyWithViewId } = useViewContext();
  const { hasRole } = useContext(AuthorizationContext);
  const hasLabelingRole = useMemo(
    () => hasRole(Role.GLOBAL_LABEL_EDITOR) || hasRole(Role.GLOBAL_LABEL_VIEWER),
    [hasRole],
  );
  const LABELED_EVENTS = 'labeledEvents';
  const LABEL_TYPE = 'labeledEvents';
  const FILTERS = 'filters';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createLabelTypeMutation = useMutation<any, any, Partial<LabeledEventType>>(
    (labelType) => createLabelType(labelType),
    {
      onSuccess: () => queryClient.invalidateQueries(getQueryKeyWithViewId(LABEL_TYPE)),
    },
  );

  const updateLabelTypeMutation = useMutation(updateLabelType, {
    onSuccess: () => queryClient.invalidateQueries(getQueryKeyWithViewId(LABEL_TYPE)),
  });

  const deleteLabelTypeMutation = useMutation(deleteLabelType, {
    onSuccess: () => queryClient.invalidateQueries(getQueryKeyWithViewId(LABEL_TYPE)),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createLabeledEventMutation = useMutation<any, any, Partial<LabeledEvent>>(
    createLabeledEvent,
    {
      onSuccess: () => queryClient.invalidateQueries(getQueryKeyWithViewId(LABELED_EVENTS)),
    },
  );

  const updateLabeledEventMutation = useMutation(updateLabeledEvent, {
    onSuccess: () => queryClient.invalidateQueries(getQueryKeyWithViewId(LABELED_EVENTS)),
  });

  const deleteLabeledEventMutation = useMutation(deleteLabeledEvent, {
    onSuccess: () => queryClient.invalidateQueries(getQueryKeyWithViewId(LABELED_EVENTS)),
  });

  const updateFiltersMutation = useMutation(updateFilters, {
    onSuccess: () => queryClient.invalidateQueries(getQueryKeyWithViewId(FILTERS)),
  });

  const useLabelTypeQuery = (id: number) =>
    useQuery([getQueryKeyWithViewId(LABEL_TYPE), id], () => getLabelType(id), {
      enabled: hasLabelingRole,
    });

  const useLabelTypesQuery = () =>
    useQuery(getQueryKeyWithViewId(LABEL_TYPE), getLabelTypes, {
      enabled: hasLabelingRole,
    });

  const useLabeledEventsQuery = () =>
    useQuery([getQueryKeyWithViewId(LABELED_EVENTS)], getLabeledEvents, {
      enabled: hasLabelingRole,
    });

  const useLabeledEventsInRangeQuery = (startTime: string, endTime: string, interval: number) =>
    useQuery(
      [getQueryKeyWithViewId(LABELED_EVENTS), { startTime, endTime }],
      () =>
        getLabeledEventsInRange({ start_time: startTime, end_time: endTime, interval: interval }),
      {
        enabled: hasLabelingRole,
      },
    );

  const useLabeledEventsByLabelTypeIdQuery = (id: number) =>
    useQuery([getQueryKeyWithViewId(LABELED_EVENTS), id], () => getLabeledEventsByLabelTypeId(id), {
      enabled: hasLabelingRole,
    });

  const useFiltersQuery = () =>
    useQuery(getQueryKeyWithViewId(FILTERS), getFilters, {
      enabled: hasLabelingRole,
    });

  return {
    createLabelTypeMutation,
    updateLabelTypeMutation,
    deleteLabelTypeMutation,
    createLabeledEventMutation,
    updateLabeledEventMutation,
    deleteLabeledEventMutation,
    updateFiltersMutation,
    useLabelTypeQuery,
    useLabelTypesQuery,
    useLabeledEventsQuery,
    useLabeledEventsInRangeQuery,
    useLabeledEventsByLabelTypeIdQuery,
    useFiltersQuery,
  };
};
