import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useTusStore } from 'use-tus';

import { AppContext } from '../../context/app-context';
import { useTenant } from '../../context/tenant-context';
import { useAuthorization } from '../../hooks/authorization';
import { truncate } from '../../utils/text';
import { FileDataProps, FileUploadContext } from '../file-upload-modal/file-upload-context';
import { NotificationData } from '../header/banners/notifications/notification-banner';

import { useNetworkStatus, usePrevious } from '@controlrooms/hooks';

export const FileUpload: React.FC = () => {
  // File Upload
  const isOnline = useNetworkStatus();
  const previousOnlineStatus = usePrevious(isOnline);

  const { upload, setUpload } = useTusStore('cr-batch-upload', { autoStart: true });

  const { updateProgress, averageProgress, tenantName } = useContext(FileUploadContext);
  const { notification, setNotification } = useContext(AppContext);
  const { getAccessTokenSilently } = useAuthorization();
  const { tenant } = useTenant();
  const { files, setFiles } = useContext(FileUploadContext);

  const prevFileRef: React.MutableRefObject<Map<string, FileDataProps> | undefined> = useRef();

  // ===POC for file preview===
  // File Preview
  // const [, setRows] = useState<Array<string[]>>([]);

  /* Preview the upload content */
  // useEffect(() => {
  //   const recursiveBlobRead = async (size: number) => {
  //     const text = await file.slice(0, size, 'utf-8').text();
  //     const rowArray = csvToArray(text);
  //     if (size > 1024 * 16) {
  //       throw new Error('Max blob size exceeded');
  //     } else if (file.size <= size) {
  //       // File does not contain 3 rows
  //       setRows(rowArray);
  //     } else if (rowArray.length < 3 || rowArray[0].length != rowArray[2].length) {
  //       console.log('row array', rowArray.length, size * 2);
  //       await recursiveBlobRead(size * 2);
  //     } else {
  //       setRows(rowArray);
  //     }
  //   };

  //   recursiveBlobRead(256);
  // }, [file]);

  /* Handle network loss / reconnection */
  useEffect(() => {
    if (upload && isOnline && !previousOnlineStatus) {
      upload.findPreviousUploads().then((previousUploads) => {
        console.log('Connection reestablished... resuming upload.');
        console.log('resuming from previous', previousUploads);
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }
        upload.start();
      });
    }
  }, [upload, isOnline, previousOnlineStatus]);

  useEffect(() => {
    const notificationData = notification as NotificationData;
    const fileId = notificationData?.payload?.file_request_id as string;
    if (files.get(fileId) && files.get(fileId)?.status !== 'Complete') {
      updateProgress(fileId, 100, 'Complete');
    }
  }, [notification, files, updateProgress]);

  useEffect(() => {
    if (averageProgress === 100) {
      setNotification(`Data upload for ${truncate(tenantName, 25)} plant is complete.`);
    }
  }, [averageProgress, setNotification, tenantName]);

  const calculateEta = useCallback(
    (fileId: string, bytesSent: number, bytesTotal: number) => {
      const progress = Math.floor((bytesSent / bytesTotal) * 100);
      updateProgress(fileId, progress);
    },
    [updateProgress],
  );

  useEffect(() => {
    files.forEach((fileData, fileId) => {
      const file = fileData?.file;
      // Prevent upload from looping. Not sure why it happens, but this fixes it.
      if (fileData.progress > 0) {
        return;
      }

      setNotification(`Data for ${truncate(tenantName, 25)} will be ready in a few minutes.`);

      updateProgress(fileId, 0);
      setUpload(file, {
        overridePatchMethod: true,
        removeFingerprintOnSuccess: true,
        endpoint: `${import.meta.env.REACT_APP_CONTROLROOMS_API}/batch-manager/files`, // FIXME: Batch Manager was sunset on 2024-05-01
        retryDelays: [0, 3000, 6000, 12000, 24000],
        metadata: {
          filename: file?.name,
          filetype: file?.type,
        },
        onBeforeRequest: async (req) => {
          const jwt = await getAccessTokenSilently({ authorizationParams: { tenant } });
          req.setHeader('authorization', `Bearer ${jwt}`);
          req.setHeader('FILE-REQUEST-ID', fileId);
        },
        onProgress: (bytesSent, bytesTotal) => {
          calculateEta(fileId, bytesSent, bytesTotal);
          prevFileRef.current = files;
        },
        onSuccess: () => {
          updateProgress(fileId, 100);
        },
        onError: () => updateProgress(fileId, null), // TODO
      });
    });
  }, [
    calculateEta,
    files,
    getAccessTokenSilently,
    setFiles,
    setUpload,
    tenant,
    updateProgress,
    setNotification,
    tenantName,
  ]);

  return null;
};
