import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { useAnalytics } from '../../../../analytics';
import { useTenant } from '../../../../context/tenant-context';
import { useUpdateUserPreferences, useUser } from '../../../../hooks/accounts';

import { RadioButton } from '@controlrooms/components';
const clearCache = async () => {
  // Clear localStorage
  localStorage.clear();

  // Clear sessionStorage
  sessionStorage.clear();

  // Optionally: unregister any service workers (if any)
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      registration.unregister();
    }
  }
};
export const AppVersion: React.FC = () => {
  const { data: user } = useUser();
  const { mutate: updateUserPreferences } = useUpdateUserPreferences();
  const { track } = useAnalytics();
  const { tenant: tenantId } = useTenant();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const updatePref = async (version: number) => {
    const _prefs = { app_version: version };
    track('APP_VERSION_UPDATE', {
      app_version: version,
    });
    updateUserPreferences({ ...user?.preferences, ..._prefs });
    clearCache().then(async () => {
      await getAccessTokenSilently({ cacheMode: 'off', authorizationParams: { tenant: tenantId } });
      navigate('/');
    });
  };

  const userDomain = user?.primary_email.split('@')[1];

  return (
    <div>
      <div>
        <div style={{ fontWeight: 'bold', margin: '5px 0' }}>App Version:</div>
        <br />
        <div data-testid={`version-1-radio`}>
          <RadioButton
            checked={user?.preferences?.app_version === 1}
            id={`version-1`}
            value={1}
            onChange={() => {
              updatePref(1);
            }}
          >
            Version 1
          </RadioButton>
        </div>
        {userDomain === 'controlrooms.ai' && (
          <div data-testid={`version-2-radio`}>
            <RadioButton
              checked={user?.preferences?.app_version === 2}
              id={`version-2`}
              value={2}
              onChange={() => {
                updatePref(2);
              }}
            >
              Version 2 (Beta)
            </RadioButton>
          </div>
        )}
      </div>
      <br />
    </div>
  );
};
