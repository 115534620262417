import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { useAnalytics } from '../../analytics';
import { AnalyzeContext } from '../../context/analyze-context';
import { SearchContext } from '../../context/search-context';
import { FolderSort, useFilteredFolders, usePlantFolders } from '../../hooks/folders';
import { SidebarFooter } from '../../pages/monitor/styles';
import { isDemoApp } from '../../utils/app';

import { ExpandIcon } from './styles/styles';
import {
  SubSystemContainer,
  SubSystemViewContainer,
  SystemSectionContainer,
} from './styles/system-styles';
import { SubSystemTagView, SystemAndTagHeaderContent, TagComponent } from './tag-view';

import { Checkbox, Icon, StyledCommonTooltip, Tooltip } from '@controlrooms/components';
import {
  Checkable,
  FlattenedFolderTag,
  Navigable,
  ParentTag,
  SubSystem,
  System,
  Tag,
} from '@controlrooms/models';
import { sanitizeString } from '@controlrooms/utils';

// Helper function to flatten the folder structure
const flattenFolders = (folder: SubSystem, level = 0): FlattenedFolderTag[] => {
  const result: FlattenedFolderTag[] = [];

  // If it's a folder (SubSystem with tags)
  result.push({ type: 'folder', item: folder, level });

  // Add all the tags under the folder
  folder.tags.forEach((tag: Tag) => {
    result.push({
      type: 'tag',
      item: { ...tag, folder: folder.folder, folderName: folder.name },
      level: level + 1, // Tags are nested under their folder
    });
  });

  // Recursively process subfolders with an increased level (indentation)
  folder.subfolders.forEach((subfolder) => {
    result.push(...flattenFolders(subfolder, level + 1)); // Increase level for subfolders
  });

  return result;
};

const flattenSystemFolders = (system: System<SubSystem>): FlattenedFolderTag[] => {
  const result: FlattenedFolderTag[] = [];

  // Add the system as a root folder
  result.push({ type: 'root', item: system, level: 0 });

  // Process each subfolder
  system.subfolders.forEach((subfolder) => {
    result.push(...flattenFolders(subfolder, 1)); // Subfolders start at level 1
  });

  return result;
};

interface SearchViewProps {
  handleCheckboxCheck: (id: number, tag?: ParentTag) => void;
  pinnedTags?: ParentTag[];
  onNav: (folderId: number) => void;
}

export const SearchView: React.FC<SearchViewProps> = ({
  handleCheckboxCheck,
  pinnedTags,
  onNav,
}) => {
  const { searchTerm } = useContext(SearchContext);
  const { selectedFolders } = useContext(AnalyzeContext);
  const { data: plant } = usePlantFolders();
  const handleNav = (id: number) => id && onNav(id);

  // Filter the folders using the custom hook
  const data = useFilteredFolders(plant?.subfolders || []);

  // Flatten the folder structure
  const flatList = useMemo(() => {
    if (!data.length) return [];
    return data.flatMap((folder) => flattenSystemFolders(folder));
  }, [data]);

  const { track } = useAnalytics();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const subSystemParams = search.get('SUB_SYSTEMS_SEARCH_PARAM');
  const tagNameParams = search.get('TAGS_SEARCH_PARAM');

  const removeQueryParams = () => {
    navigate({ pathname: '/analyze', search: '' });
  };

  return (
    <div className="search-view">
      <SystemSectionContainer className="expanded">
        <Virtuoso
          style={{ height: 'calc(100vh - 226px)' }}
          className="search-container"
          totalCount={flatList.length}
          itemContent={(index) => {
            const { type, item, level } = flatList[index];

            // Set the paddingLeft or margin to represent the tree hierarchy based on level
            const indentationStyle = { paddingLeft: `${(level || 1) * 20}px` };

            // Render Root Folders (System<SubSystem>)
            if (type === 'root') {
              const system = item as System<SubSystem>;
              return (
                <div
                  className="section-header"
                  data-testid="section-header"
                  style={indentationStyle}
                >
                  <Highlighter
                    searchWords={[searchTerm]}
                    textToHighlight={system.name}
                    autoEscape={true}
                    activeStyle={indentationStyle}
                  />
                  {system.description && system.name && <span>:&nbsp;</span>}
                  {system.description && (
                    <div className="description">
                      <Highlighter
                        searchWords={[searchTerm]}
                        textToHighlight={system.description}
                        autoEscape={true}
                      />
                    </div>
                  )}
                </div>
              );
            }

            // Render Folders (SubSystem)
            if (type === 'folder') {
              const folder = item as SubSystem;
              const id = item.folder || 0;
              const isAllChecked = Boolean(selectedFolders?.includes(item.folder || 0));
              const hasTags = folder.tags.length > 0;
              const hasSubfolders = !!folder.subfolders?.length;
              const subSysViewContainerClass = classNames({
                'search-result': searchTerm.length,
                expanded: true,
              });

              const subSysContainerClass = classNames({
                checked: isAllChecked,
                'no-tags': !hasTags && hasSubfolders,
              });

              return (
                <SubSystemViewContainer className={subSysViewContainerClass}>
                  <div className="header sub-system-tag-header">
                    <SubSystemContainer className={subSysContainerClass}>
                      <Checkbox
                        checked={isAllChecked}
                        className="sub-system-check"
                        onChange={() => {
                          if (!isDemoApp(location.pathname) && (subSystemParams || tagNameParams)) {
                            removeQueryParams();
                          }
                          track('Analyze - SubSystem Tag View', {
                            checkedSubSystem: isAllChecked,
                            checkedSubSystemId: id,
                          });
                          handleCheckboxCheck(id);
                        }}
                      />
                      <Tooltip label={`${folder?.infra_display_name} / ${folder?.description}`}>
                        <div
                          className="sub-system-label"
                          onClick={() => {
                            if (searchTerm.length) handleNav(id);
                          }}
                          style={indentationStyle}
                        >
                          <SystemAndTagHeaderContent
                            name={folder?.infra_display_name || ''}
                            description={folder?.description || ''}
                            isHeader
                          />
                        </div>
                      </Tooltip>
                      <div
                        onClick={() => {
                          if (searchTerm.length) handleNav(id);
                        }}
                      >
                        {hasTags && !searchTerm && <ExpandIcon />}
                        <div className="nav-icon">
                          <Icon name="chevron" width="10" height="10" />
                        </div>
                      </div>
                    </SubSystemContainer>
                  </div>
                  <StyledCommonTooltip id="tag-name-description" />
                </SubSystemViewContainer>
              );
            }

            // Render Tags
            if (type === 'tag') {
              const tag = item as Tag;
              return (
                <TagComponent
                  key={tag.name}
                  folder={tag.folder || 0}
                  handleCheckboxCheck={handleCheckboxCheck}
                  pinnedTags={pinnedTags}
                  {...tag}
                  level={level}
                />
              );
            }

            return null;
          }}
        />
      </SystemSectionContainer>
    </div>
  );
};

export const _SearchView: React.FC<Checkable & Navigable> = ({
  handleCheckboxCheck,
  pinnedTags,
  onNav,
}) => {
  const { searchTerm, setSearchInputValue, setSearchTermOnNavigate } = useContext(SearchContext);
  const { selectedFolders } = useContext(AnalyzeContext);

  const { data: plant } = usePlantFolders(FolderSort.DEFAULT);

  const data = useFilteredFolders(plant?.subfolders || []);

  const { track } = useAnalytics();

  const renderTag = (subfolder: SubSystem & { parentPath?: string }) => {
    let aggregateView: JSX.Element = <></>;
    const view = (viewSf: SubSystem & { parentPath?: string }) => (
      <SubSystemTagView
        {...viewSf}
        tags={viewSf.tags as Tag[] & ParentTag[]}
        key={sanitizeString(`${subfolder.folder}-${viewSf.name}`)}
        handleCheckboxCheck={handleCheckboxCheck}
        parentFolder={0}
        parentPath={viewSf.parentPath as string}
        expanded={true}
        pinnedTags={pinnedTags}
        selectedFolders={selectedFolders}
        onNav={onNav}
      ></SubSystemTagView>
    );

    aggregateView = view(subfolder);

    aggregateView = (
      <div key={sanitizeString(`${subfolder.folder}-${subfolder.name}`)}>
        {aggregateView}
        {subfolder.subfolders.map((sf2: SubSystem) => renderTag(sf2))}
      </div>
    );

    return aggregateView;
  };

  return (
    <div className="search-view">
      {data?.map((system) => (
        <SystemSectionContainer className="expanded" key={`${system.folder}:${system.description}`}>
          <div className="section-header" data-testid="section-header">
            <Highlighter
              searchWords={[searchTerm]}
              textToHighlight={system.name}
              autoEscape={true}
            ></Highlighter>
            {system.description && system.name && <span>:&nbsp;</span>}
            {system.description && (
              <div className="description">
                <Highlighter
                  searchWords={[searchTerm]}
                  textToHighlight={system.description}
                  autoEscape={true}
                ></Highlighter>
              </div>
            )}
          </div>
          <div className="section-content" data-testid="section-content">
            <div>
              {system.subfolders?.map((subfolder) => {
                return (
                  <div key={sanitizeString(`${subfolder.folder}-${subfolder.name}`)}>
                    {renderTag(subfolder)}
                  </div>
                );
              })}
            </div>
          </div>
        </SystemSectionContainer>
      ))}

      <SidebarFooter>
        <div className="gta-wrapper">
          <button
            className="link"
            onClick={() => {
              track('Analyze - Search', {
                clearSearch: 'clicked',
              });
              setSearchInputValue('');
              setSearchTermOnNavigate('');
            }}
          >
            <span>Close</span>
          </button>
        </div>
      </SidebarFooter>
    </div>
  );
};
