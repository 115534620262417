import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { useLayoutContext } from '../../context/layout-context';
import { useViewContext } from '../../context/view-context';

import { SeverityFilterContainer } from './styles';

import { Switch } from '@controlrooms/components';
import { ALERT_SEARCH_PARAM } from '@controlrooms/constants';

interface Props {
  label?: string;
  showSlider?: boolean;
}

const LimitsToggle: React.FC<Props> = ({ label }) => {
  const { viewState, handleShowLimits } = useViewContext();
  const { activeModes, activeView } = useLayoutContext();
  const selectedMode = activeModes[activeView];

  const { showLimits } = viewState.view[selectedMode];
  const [searchParams, setSearchParams] = useSearchParams();
  const alertView = searchParams.get(ALERT_SEARCH_PARAM);

  return (
    <SeverityFilterContainer style={{ marginLeft: '1rem', gap: '0' }}>
      {label ? <span className="label">{label}</span> : <span className="label">Show Limits</span>}
      <div>
        <Switch
          selected={showLimits}
          onClick={(e, selected) => {
            if (alertView) {
              setSearchParams('');
            }
            handleShowLimits(selected);
          }}
          dataTestId={'limits'}
        />
      </div>
    </SeverityFilterContainer>
  );
};

export default LimitsToggle;
