import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { useAnalytics } from '../../../app/analytics';
import Collapsible from '../../../app/components/collapsible-folder/collapsible-folder';
import { useHeatmap } from '../../../app/hooks/anomalies';
import { FlattenedFolder, FolderSort } from '../../../app/hooks/folders';
import { useViewContext } from '../../context/view-context';
import { FolderHeatmap, FolderSystems, StyledSubFolderRow } from '../../pages/monitor/styles';
import { SubFolder } from '../../pages/monitor/sub-folder';

import { Heatmap } from './heatmap';

import { SubSystem, SubSystemAnomalous } from '@controlrooms/models';

interface HeatmapGroupProps {
  _folders: FlattenedFolder[];
  selectedFolders: Array<number>;
  handleHeaderCheckboxCheck: (id: number) => void;
  handleCheckboxCheck: (id: number, parentId: number) => void;
  handleAnalyzeLink: (systemId?: number) => void;
  width: number;
}

export const HeatmapGroup: React.FC<HeatmapGroupProps> = ({
  _folders,
  selectedFolders,
  handleHeaderCheckboxCheck,
  handleCheckboxCheck,
  handleAnalyzeLink,
  width,
}) => {
  const { viewState } = useViewContext();
  const { timeSelection } = viewState;
  const { track } = useAnalytics();

  const [collapsedFolders, setCollapsedFolders] = useState<number[]>([]);
  const folders = useMemo(() => {
    return _folders.filter(({ type, rootFolderId = 0 }) => {
      const isRoot = type === 'root';
      const shouldCollapse = collapsedFolders.includes(rootFolderId);
      return !shouldCollapse || isRoot;
    });
  }, [collapsedFolders, _folders]);

  const {
    data: heatmapData,
    isLoading: isLoadingHeatmapData,
    interval,
    labeledEvents,
    fetchNextPage,
    fetchPreviousPage,
  } = useHeatmap(
    {
      startTime: timeSelection.startTime,
      endTime: timeSelection.endTime,
      streamingTimeInSeconds: timeSelection.streamingTimeInSeconds,
    },
    FolderSort.DEFAULT,
  );

  const isAnomalousMap = useMemo(() => {
    if (!heatmapData) return {};
    return Object.values(heatmapData.pages[0].result).reduce<Record<number, boolean>>(
      (acc, { folder, anomalies }) => {
        acc[folder] = anomalies?.some(({ value }) => value > 0);
        return acc;
      },
      {},
    );
  }, [heatmapData]);

  const buildSubFolder = (
    id: number,
    parentId: number,
    subsystem: SubSystem,
    wideSpacing: boolean,
    lastSystem: boolean,
  ) => {
    const subSystemAnomalous = {
      ...subsystem,
      isAnomalous: Boolean(isAnomalousMap[id]),
    } as SubSystemAnomalous;
    return (
      <StyledSubFolderRow
        data-testid="subfolder-row"
        key={id}
        data-parentid={parentId}
        className={classNames(`folder-row folder-row-${id} folder-parent-${parentId}`, {
          selected: selectedFolders?.includes(id),
        })}
      >
        <SubFolder
          lastSystem={lastSystem}
          wideSpacing={wideSpacing}
          subsystem={subSystemAnomalous}
          width={width}
          handleCheckboxCheck={() => {
            track('Monitor - Select SubSystem', {
              selectedSubSystemId: id,
              selectedSubSystemName: subsystem.name,
              selectedParentId: parentId,
            });
            handleCheckboxCheck(id, parentId);
          }}
          handleAnalyzeLink={() => {
            track('Monitor - Go to Analyze via SubSystem', {
              heatmap: 'clicked',
              subsystemId: id,
            });
            handleAnalyzeLink(id);
          }}
          isChecked={selectedFolders?.includes(id) || false}
          showAnalyzeLink={!selectedFolders.includes(id)}
        />
        <FolderHeatmap style={{ width: `calc(100% - ${width}px)` }}>
          {!isLoadingHeatmapData && (
            <Heatmap
              wideSpacing={wideSpacing}
              handleAnalyzeLink={() => {
                handleAnalyzeLink(id);
              }}
              folderId={id}
              heatmapData={heatmapData}
              labeledEvents={labeledEvents}
              interval={interval}
              fetchNextPage={fetchNextPage}
              fetchPreviousPage={fetchPreviousPage}
            />
          )}
        </FolderHeatmap>
      </StyledSubFolderRow>
    );
  };
  const virtuosoHeight = useMemo(() => {
    let height = 0;
    folders.forEach((folder) => {
      if (folder.type === 'root') {
        height += 21;
      } else {
        height += 30;
      }
    });
    return height;
  }, [folders]);

  return (
    <>
      <FolderSystems className="folder-systems_">
        <Virtuoso
          className="heatmap-virtuoso"
          style={{ maxHeight: `calc(100vh - 150px)`, height: `${virtuosoHeight}px` }} // 50px for header and 100px for filter
          totalCount={folders.length}
          increaseViewportBy={50}
          itemContent={(index: number) => {
            const subsystem = folders[index];
            if (subsystem.type === 'root') {
              return (
                <Collapsible
                  key={subsystem.folder.folder}
                  open
                  description={subsystem.folder.description}
                  name={subsystem.folder.name}
                  className={`folder-container-${subsystem.folder.folder}`}
                  isChecked={selectedFolders?.includes(subsystem.folder.folder) || false}
                  handleCheckboxCheck={() => {
                    track('Monitor - Select System', {
                      selectedSystemId: subsystem.folder.folder,
                      selectedSystemName: subsystem.folder.name,
                    });
                    handleHeaderCheckboxCheck(subsystem.folder.folder);
                  }}
                  hasChildren={!collapsedFolders.includes(subsystem.folder.folder)}
                  onClick={() => {
                    setCollapsedFolders((prev) => {
                      const shouldExpand = prev.includes(subsystem.folder.folder);
                      if (shouldExpand) {
                        return prev.filter((id) => id !== subsystem.folder.folder);
                      }
                      return [...prev, subsystem.folder.folder];
                    });
                  }}
                />
              );
            }
            const lastSystem = index === folders.length - 1;
            const wideSpacing = false;
            return buildSubFolder(
              subsystem.folder.folder,
              subsystem.rootFolderId || 0,
              subsystem.folder,
              wideSpacing,
              lastSystem,
            );
          }}
        />
      </FolderSystems>
    </>
  );
};
