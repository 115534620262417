import { AxiosError } from 'axios';
import { useState, useRef, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useTenant } from '../../../app/context/tenant-context';
import {
  useUserId,
  useGetUserViewsV2,
  useGetUserSharedViewsV2,
  getUserSharedViewsPropsKey,
  getUserViewsPropsKey,
} from '../../../app/hooks/accounts';
import { useEnsembleModelsQuery } from '../../../app/hooks/tags';
import {
  useSaveView,
  useAddToSharedViews,
  useUpdateView,
  useRemoveViewFromSharedViews,
  useGetView,
} from '../../../app/hooks/view';
import { useLayoutContext } from '../../context/layout-context';
import {
  useViewContext,
  transformToViewState,
  constructPersistedView,
} from '../../context/view-context';

import { ConfirmCloseModal } from './ConfirmCloseModal';
import { ConfirmDeleteModal } from './ConfirmDeleteModal';
import { SaveViewModal } from './SaveViewModal';
import { ShareViewLinkModal } from './ShareViewLinkModal';
import { Menu, preparePrimaryLabel, Tab } from './TabbedContainer';
import { ViewTooltipComponent } from './ViewTooltipComponent';

import { Icon, Tooltip } from '@controlrooms/components';
import { ICONS } from '@controlrooms/constants';
import { colors } from '@controlrooms/design-tokens';
import { useClickOutside } from '@controlrooms/hooks';
import {
  ViewState,
  ViewType,
  PersistView,
  TimeRangesType,
  ViewShareType,
} from '@controlrooms/models';

export const ViewTab = ({ view_id }: { view_id: string }) => {
  const {
    setActiveView,
    setViewIds,
    setActiveModes,
    activeView,
    activeModes,
    setSavedViewIds,
    viewCollection,
    setViewCollection,
  } = useLayoutContext();
  const { viewState, duplicateView, setViewState, deleteView, closeView } = useViewContext();
  const currentUserId = useUserId();
  const { ensembleFamilies } = useEnsembleModelsQuery();
  const { mutateAsync: createView, error: createViewError } = useSaveView();
  const { mutateAsync: addToSharedViewList } = useAddToSharedViews();
  const { mutateAsync: updatePersistedView, error: updateViewError } = useUpdateView();
  const { mutateAsync: removeFromSharedViewList } = useRemoveViewFromSharedViews();
  const { data: userViews } = useGetUserViewsV2();
  const { data: userSharedViews } = useGetUserSharedViewsV2();

  const viewStateViewId = useMemo(() => {
    if (viewState.viewId === 'default') {
      if (viewState.view_id !== '') {
        return viewState.view_id;
      }
      return '';
    } else {
      return viewState.view_id;
    }
  }, [viewState.viewId, viewState.view_id]);
  const { data: currPersistView } = useGetView(viewStateViewId);

  const [, setSearchParams] = useSearchParams();

  const hasDefaultView = userViews?.some((view) => view.default_view === true);
  const isSharedView = userSharedViews?.some((view) => view.view_id === view_id);

  // A view can be deleted if its owner is the current user
  // or if the view is shared and the view type is not viewable or anonymous
  const isDeletable = useMemo(() => {
    return Number(viewState.userId) === Number(currentUserId);
  }, [viewState.userId, currentUserId]);

  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [viewSnapshot, setViewSnapshot] = useState<ViewState | undefined>();
  const [showSaveNewViewModal, setShowSaveNewViewModal] = useState(false);
  const [showManageViewModal, setShowManageViewModal] = useState(false);

  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);

  const subMenuRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const viewMode = useMemo(() => {
    return activeModes[view_id] === ViewType.ANALYZE ? 'Assess' : activeModes[view_id];
  }, [activeModes, view_id]);

  useClickOutside(subMenuRef, () => setOpenSubMenu(false));

  const { tenant } = useTenant();
  const queryClient = useQueryClient();

  const generateView = (view: PersistView) => {
    const ensembleFamily = ensembleFamilies.find(
      (e) => e.family_id === view?.view?.ensemble_family_id,
    );
    view.view = {
      ...view.view,
      timeSelection: {
        ...view.view.timeSelection,
        timePreset: 'custom',
        timeRange: 'custom' as TimeRangesType,
        streamingTimeInSeconds: 0,
      },
    };
    const viewState = transformToViewState(view, ensembleFamily || null, tenant);
    sessionStorage.setItem(view.view_id || 'error', JSON.stringify(viewState));
    return viewState;
  };

  // Update view
  const handleUpdate = async () => {
    const persistView = constructPersistedView({
      viewState,
      viewName: viewState.name || '',
      activeModes,
      currentUserId,
      viewDescription: '',
      shareType: viewState.shareType || ViewShareType.PRIVATE,
      shared: false,
    });
    try {
      await updatePersistedView({
        view: persistView,
        view_id: viewState.view_id,
      });
      setViewState((prev) => ({
        ...prev,
        isDirty: false,
      }));
      queryClient.refetchQueries(['user-collections', currentUserId]);
      queryClient.refetchQueries(['collection', viewCollection.collectionId]);
      queryClient.refetchQueries(['user-views', currentUserId]);
      queryClient.refetchQueries(['view', viewState.view_id]);
      queryClient.refetchQueries(['user-shared-views', currentUserId]);
      queryClient.refetchQueries(['user-shared-collections', currentUserId]);
    } catch (error) {
      console.error(error);
    }
  };
  // Create new default view if not exists
  const handleCreateDefaultView = async () => {
    const persistView = constructPersistedView({
      viewState,
      viewName: 'default',
      activeModes,
      currentUserId,
      viewDescription: '',
      shareType: ViewShareType.PRIVATE,
      shared: false,
    });
    try {
      const hash = await createView(persistView);
      setViewState((prev) => ({
        ...prev,
        isDirty: false,
        view_id: hash,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setOpenSubMenu(false);
    }
  };

  const handleAddToShared = async () => {
    try {
      await addToSharedViewList({
        viewId: viewState.view_id,
        payload: { user_ids: [currentUserId] },
      });
      queryClient.invalidateQueries(getUserSharedViewsPropsKey(currentUserId));
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveFromShared = async () => {
    try {
      await removeFromSharedViewList(viewState.view_id);
      queryClient.invalidateQueries(getUserSharedViewsPropsKey(currentUserId));
    } catch (error) {
      console.error(error);
    }
  };

  // Handle revert
  const handleRevert = () => {
    if (!currPersistView) return;
    const prevviewState = generateView(currPersistView);
    setViewState(prevviewState);
  };

  // Change menu position based on parent element position
  const changeMenuPosition = () => {
    const parentPosition = subMenuRef.current?.getBoundingClientRect().right;
    if (menuRef.current && parentPosition) {
      menuRef.current.style.left = `${parentPosition - 30}px`;
    }
  };

  const enableSaveChange = useMemo(() => {
    if (Number(viewState.userId) === Number(currentUserId)) {
      if (viewState.viewId === 'default') {
        if (!hasDefaultView) {
          return true;
        }
        return viewState.isDirty && viewState.view_id !== '';
      } else if (viewState.viewId !== 'default' && viewState.view_id !== '') {
        return viewState.isDirty;
      }
    } else {
      return viewState.shareType === ViewShareType.EDITABLE;
    }
    return false;
  }, [
    currentUserId,
    hasDefaultView,
    viewState.isDirty,
    viewState.shareType,
    viewState.userId,
    viewState.viewId,
    viewState.view_id,
  ]);

  useEffect(() => {
    changeMenuPosition();
    document?.getElementById('tab-container')?.addEventListener('scroll', changeMenuPosition);

    return () => {
      document?.getElementById('tab-container')?.removeEventListener('scroll', changeMenuPosition);
    };
  }, [openSubMenu]);

  return (
    <Tab key={view_id} isActive={activeView === view_id}>
      <div className="tab-content">
        <div
          style={{ display: 'flex', alignItems: 'center', gap: '5px', paddingLeft: '5px' }}
          onClick={(e) => {
            e.stopPropagation();
            setActiveView(view_id);
            setSearchParams('');
          }}
        >
          {view_id === 'default' && (
            <Icon name={ICONS.Home} width="20" height="20" color="#A0A38D" />
          )}
          <span className="label">
            <div
              style={{
                position: 'fixed',
                alignItems: 'center',
                backgroundColor: 'white',
                top: '20px',
              }}
            ></div>
            <Tooltip
              label={
                <ViewTooltipComponent
                  name={viewState.name || 'Unnamed View'}
                  description={viewState.description}
                  createdBy={viewState.created_by || ''}
                />
              }
              theme={'dark'}
            >
              {(viewState.isDirty ||
                (viewState.viewId !== 'default' && viewState.view_id === '')) && (
                <span style={{ color: colors.datavis['pink-2'], marginRight: '5px' }}>*</span>
              )}
              {viewState.name === 'default'
                ? ''
                : `${viewState.name === '' ? 'Unnamed view' : viewState.name} :`}{' '}
              {viewMode} - {preparePrimaryLabel(viewState.timeSelection)}
            </Tooltip>
          </span>
        </div>
        <div ref={subMenuRef}>
          <Icon
            className="subMenu-link"
            name={ICONS.Chevron}
            width="10"
            color="#A0A38D"
            onClick={(e) => {
              e.stopPropagation(); // Prevent tab selection when clicking submenu link
              setOpenSubMenu((prev) => !prev);
            }}
          />
          {openSubMenu && (
            <Menu ref={menuRef}>
              <ul>
                {view_id !== 'default' && (
                  <li
                    className="option"
                    onClick={() => {
                      setShowSaveNewViewModal(true);
                      setOpenSubMenu(false);
                    }}
                  >
                    Save as new view
                  </li>
                )}
                {viewState.shareType !== ViewShareType.ANONYMOUS && (
                  <li
                    className={`option ${enableSaveChange ? '' : 'disabled'}`}
                    onClick={() => {
                      if (enableSaveChange) {
                        if (viewState.viewId === 'default' && !hasDefaultView) {
                          handleCreateDefaultView();
                        } else {
                          handleUpdate();
                        }
                        setOpenSubMenu(false);
                      }
                    }}
                  >
                    Save Changes
                  </li>
                )}
                <li
                  className={`option ${
                    viewState.isDirty && viewState.view_id !== '' ? '' : 'disabled'
                  }`}
                  onClick={() => {
                    if (viewState.isDirty && viewState.view_id !== '') {
                      handleRevert();
                      setOpenSubMenu(false);
                    }
                  }}
                >
                  Revert
                </li>
                {viewState.viewId !== 'default' &&
                  Number(viewState.userId) === Number(currentUserId) && (
                    <li
                      className={`option ${viewState.view_id !== '' ? '' : 'disabled'}`}
                      onClick={() => {
                        if (viewState.view_id !== '') {
                          setShowManageViewModal(true);
                          setOpenSubMenu(false);
                        }
                      }}
                    >
                      Manage
                    </li>
                  )}
                {/* TODO: Add version history */}
                {/* <li className="option">Version history</li> */}
                <li
                  className="option"
                  onClick={() => {
                    duplicateView();
                    setOpenSubMenu(false);
                  }}
                >
                  Duplicate
                </li>
                {view_id !== 'default' && viewState.view_id !== '' && isDeletable && (
                  <li
                    className="option"
                    onClick={() => {
                      setConfirmDeleteModal(true);
                      setOpenSubMenu(false);
                    }}
                  >
                    Delete
                  </li>
                )}
                <li className="separator"></li>
                {view_id !== 'default' && (
                  <li
                    className="option"
                    onClick={() => {
                      setConfirmCloseModal(true);
                      setOpenSubMenu(false);
                      return;
                    }}
                  >
                    Close
                  </li>
                )}
                {viewState.userId !== undefined &&
                  Number(viewState.userId) !== Number(currentUserId) &&
                  !isSharedView && (
                    <li
                      className="option"
                      onClick={async () => {
                        handleAddToShared();
                        setOpenSubMenu(false);
                        return;
                      }}
                    >
                      Add to shared views
                    </li>
                  )}
                {view_id !== 'default' &&
                  viewState.view_id !== '' &&
                  viewState.userId !== undefined &&
                  Number(viewState.userId) !== Number(currentUserId) &&
                  isSharedView && (
                    <li className="option" onClick={() => handleRemoveFromShared()}>
                      Remove from shared views
                    </li>
                  )}
                <li
                  className="option"
                  onClick={async () => {
                    if (viewState.viewId === 'default') {
                      duplicateView();
                      setOpenSubMenu(false);
                      return;
                    }
                    setViewSnapshot(viewState);
                    setShowShareModal(true);
                    setOpenSubMenu(false);
                  }}
                >
                  Share
                </li>
              </ul>
            </Menu>
          )}
        </div>
      </div>
      {/* Save new view Modal */}
      {showSaveNewViewModal && (
        <SaveViewModal
          onClose={() => {
            setShowSaveNewViewModal(false);
          }}
          apiError={createViewError}
          viewState={viewState}
          onSave={async ({ name, description, shareType }) => {
            const persistView = constructPersistedView({
              viewName: name,
              viewDescription: description,
              shareType,
              viewState,
              activeModes,
              currentUserId,
              shared: false,
            });
            try {
              const hash = await createView(persistView);
              const localState = sessionStorage.getItem(viewState.viewId);
              if (localState) {
                let _currLoacalViewState: ViewState = JSON.parse(localState);
                _currLoacalViewState = {
                  ..._currLoacalViewState,
                  viewId: hash,
                  isDirty: false,
                  shareType,
                  view_id: hash,
                  name,
                  description,
                  userId: currentUserId,
                };
                sessionStorage.setItem(hash, JSON.stringify(_currLoacalViewState));
                sessionStorage.removeItem(viewState.viewId);
              }
              setViewIds((prev) => {
                const updatedViewIds = prev.filter((id) => id !== viewState.viewId);
                if (updatedViewIds.includes(hash)) return updatedViewIds;
                return [...updatedViewIds, hash];
              });
              setSavedViewIds((prev) => {
                const updatedViewIds = prev.filter((id) => id !== viewState.viewId);
                if (updatedViewIds.includes(hash)) return updatedViewIds;
                return [...updatedViewIds, hash];
              });
              setActiveModes((prev) => ({
                ...prev,
                [hash]: activeModes[viewState.viewId],
              }));
              setActiveView(hash);
              setShowSaveNewViewModal(false);

              if (viewCollection.collectionId) {
                setViewCollection((prev) => ({
                  ...prev,
                  viewsIds: Array.from(new Set([...prev.viewsIds, hash])),
                  views: [...prev.views.filter((v) => v.view_id !== hash), persistView],
                  isDirty: true,
                }));
              }
            } catch (error) {
              console.error(error);
            }
          }}
        />
      )}
      {/* Manage saved view */}
      {showManageViewModal && (
        <SaveViewModal
          onClose={() => {
            setShowManageViewModal(false);
          }}
          isUpdateModal={true}
          apiError={updateViewError as AxiosError}
          viewState={viewState}
          onSave={async ({ name, description, shareType }) => {
            const persistView = constructPersistedView({
              viewName: name,
              viewDescription: description,
              shareType,
              viewState,
              activeModes,
              currentUserId,
              shared: false,
            });
            try {
              const key = getUserViewsPropsKey(currentUserId);
              await updatePersistedView({
                view: persistView,
                view_id: viewState.view_id,
              });
              queryClient.invalidateQueries(key);
              setViewState((prev) => ({
                ...prev,
                isDirty: false,
                name: name,
                description: description,
                shareType: shareType,
              }));
              setShowManageViewModal(false);
            } catch (error) {
              console.error(error);
            }
          }}
        />
      )}
      {/* Confirm close modal */}
      {confirmCloseModal && (
        <ConfirmCloseModal
          onConfirm={async (state) => {
            if (state) await closeView();
            if (viewCollection.collectionId) {
              setViewCollection((prev) => ({
                ...prev,
                views: prev.views.filter((view) => view.view_id !== viewState.view_id),
                viewsIds: prev.viewsIds.filter((id) => id !== viewState.view_id),
                isDirty: true,
              }));
            }
            setConfirmCloseModal(false);
          }}
        />
      )}
      {/* Confirm delete modal */}
      {confirmDeleteModal && (
        <ConfirmDeleteModal
          onConfirm={async (state) => {
            if (state) {
              try {
                await deleteView();
                if (viewCollection.collectionId) {
                  setViewCollection((prev) => ({
                    ...prev,
                    views: prev.views.filter((view) => view.view_id !== viewState.view_id),
                    viewsIds: prev.viewsIds.filter((id) => id !== viewState.view_id),
                    isDirty: true,
                  }));
                }
                if (viewState.view_id === '') closeView();
              } catch (error) {
                console.error(error);
              }
            }
            setConfirmDeleteModal(false);
          }}
        />
      )}
      {showShareModal && !!viewSnapshot?.viewId && (
        <ShareViewLinkModal
          viewSnapshot={viewSnapshot as ViewState}
          onClose={() => {
            setShowShareModal(false);
          }}
        />
      )}
    </Tab>
  );
};
