import { TitleCard } from './styles';

import { Tooltip } from '@controlrooms/components';
import { Alert } from '@controlrooms/models';

export const AlertSummary: React.FC<{ alert: Alert; targetType: string }> = ({
  alert,
  targetType,
}) => {
  const getTitleContent = () => {
    if (targetType === 'tag') {
      return (
        <div className="tag">
          <div className="path" data-testid="create-alert-tag-path">
            {alert?.alert_view_summary?.infra_display_name || ''}
          </div>
          <div>
            <span className="id" data-testid="create-alert-tag-id">
              {alert?.alert_view_summary?.target_display_name}
            </span>
            <span className="description" data-testid="create-alert-tag-description">
              {alert?.alert_view_summary?.tag_description}
            </span>
          </div>
        </div>
      );
    }
    return (
      <>
        <Tooltip label={alert?.alert_view_summary?.target_display_name || ''} place="top">
          <div
            className="system"
            style={{ maxWidth: alert?.alert_view_summary?.pinned_tag_count ? '360px' : '440px' }}
            data-testid="custom-view-name-edit-window"
          >
            {alert?.alert_view_summary?.target_display_name || ''}
          </div>
        </Tooltip>
        <div className="summary-wrapper">
          {alert?.alert_view_summary?.system_count !== null ? (
            <div className="summary">
              <div className="count" data-testid="systems-count-edit-window">
                {alert?.alert_view_summary?.system_count}
              </div>
              <div className="name">Systems</div>
            </div>
          ) : (
            ''
          )}
          {alert?.alert_view_summary?.tag_count !== null ? (
            <div className="summary">
              <div className="count" data-testid="tags-count-edit-window">
                {alert?.alert_view_summary?.tag_count}
              </div>
              <div className="name">Tags</div>
            </div>
          ) : (
            ''
          )}
          {alert?.alert_view_summary?.pinned_tag_count !== null ? (
            <div className="summary">
              <div className="count" data-testid="pinned-tags-count-edit-window">
                {alert?.alert_view_summary?.pinned_tag_count}
              </div>
              <div className="name">Pinned Tags</div>
            </div>
          ) : (
            ''
          )}
        </div>
      </>
    );
  };

  return <TitleCard>{getTitleContent()}</TitleCard>;
};
